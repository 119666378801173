import React from 'react';
import Div from "@jumbo/shared/Div";
import Link from "@mui/material/Link";
import {ASSET_IMAGES} from "../../utils/constants/paths";
import { WhiteLogo } from 'app/assets/svg/logo';




const Logo = ({mini, mode, sx}) => {
    return (
        <Div className='logo_header' sx={{display: "inline-flex", ...sx, flex:"1 1 auto"}}>
            <Link href={'/home'}>
                {
                    !mini ?
                       <WhiteLogo />
                    //  <img src={} alt='whitlogo' />
                      
                        :
                        <img src={mode === "light" ? `${ASSET_IMAGES}/logo-short.png` : `${ASSET_IMAGES}/logo-short-white.png`} alt="Jumbo React" />
                }
            </Link>
        </Div>
    );
};

Logo.defaultProps = {
    mode: "light"
};

export default Logo;
