import React from "react";
import Page from "@jumbo/shared/Page";

import { adminRoutes, appRoutes } from "./appRoutes";
import Signup1 from "app/pages/auth-pages/signup1/Signup1";
import ForgotPassword from "app/pages/auth-pages/forgotPassword/forgotPassword";
import ResetPassword from "app/pages/auth-pages/resetPassword/resetPassword";
import SetNewPassword from "app/pages/auth-pages/setNewPassword/setNewPassword";
import NotFound from "app/pages/auth-pages/error-pages/notFound";
import SuccessMessage from "app/pages/auth-pages/setNewPassword/successMessage";
import Login1 from "app/pages/auth-pages/login1/Login1";


/**
 routes which you want to make accessible to both authenticated and anonymous users
 **/
const routesForPublic = [
    
];

/**
 routes only accessible to authenticated users
 **/
const routesForAuthenticatedOnly = [
    ...appRoutes,
    
];


const routesForNotAuthenticatedOnly = [
   /* {
        path: "/",
        element: <Page exact component={Login1} layout={"solo-page"} disableSmLogin={true}/>
    }, */
   
   {
        path: "/login",
        element: <Page  component={  Login1 } layout={"solo-page"} disableSmLogin={true}/>
    },
    {
        path: "/signup",
        element: <Page component={Signup1} layout={"solo-page"} disableSmLogin={true}/>
    },
    {
        path: "/forgot-password",
        element: <Page component={ForgotPassword} layout={"solo-page"} disableSmLogin={true}/>
    },
    {
        path: "/reset-password",
        element: <Page component={ResetPassword} layout={"solo-page"} disableSmLogin={true}/>
    },
    {
        path: "/set-password",
        element: <Page component={SetNewPassword} layout={"solo-page"} disableSmLogin={true}/>
    },
   
    {
        path: "/reset-password",
        element: <Page component={ResetPassword} layout={"solo-page"} disableSmLogin={true}/>
    },
    {
        path: "success-message",
        element: <Page component={SuccessMessage} layout={"solo-page"} disableSmLogin={true}/>
    },
   
    
  
  
    {
        path: "*",
        element: <Page component={NotFound}  disableSmLogin={true}/>
    },
];

const routesForAdmin = [
//    ...adminRoutes
];

 

const routes = [
    ...routesForNotAuthenticatedOnly,
    ...routesForPublic,
    ...routesForAuthenticatedOnly,
    ...routesForAdmin
];



export {routes as default, routesForPublic, routesForNotAuthenticatedOnly, routesForAuthenticatedOnly};

 
 