import React, {useState} from 'react';
import Avatar from "@mui/material/Avatar";
import {ListItemIcon, ListItemText, ThemeProvider} from "@mui/material";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";

import JumboDdPopover from "@jumbo/components/JumboDdPopover";
import Div from "@jumbo/shared/Div";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";

import { logoutAction } from 'app/redux/actions/authAction';

import useApp from 'app/hooks/useApp';


import ChangePassword from 'app/pages/app-pages/Profile/changePassword';
import { SlLock } from "react-icons/sl";

import { AiOutlineLogin } from "react-icons/ai";
import { IoIosArrowDown } from "react-icons/io";


const AuthUserDropdown = () => {
   
    const {theme} = useJumboTheme();
    const user = useApp();
    console.log(user, '1321564654')

   // const [anchorE1, setanchorEl] = useState(null)
    const [mobileOpen, setMobileOpen] = useState(false);
    const [managePinsOpen, setManagePinsOpen] = useState(false);
    const [changePassOpen, setChangePassOpen] = useState(false);
    
    const [anchorEl, setAnchorEl] = React.useState(null);
    let isOpen = Boolean(anchorEl);

  
      
      function handleChangePassToggle() {
        setChangePassOpen(true);
        setManagePinsOpen(false);
        setMobileOpen(false);
        setAnchorEl(null)
    }

    function handleChangePassClose() {
        setChangePassOpen(false);
        setAnchorEl(null)
    }
    
    // const userData = user.userData
    // const profile_pic = getAssetPath(`${ASSET_AVATARS}/avatar.png`, `60x60`);
    const onLogout = () => {
        logoutAction();
        window.location.reload(false);
    };

    // const handleProfile = () => {
    //     navigate('/change-profile')
    // }


/*menu popover */

  const popuphandleClick = React.useCallback((event) => {
      //isOpen = Boolean(anchorEl);
     
     setAnchorEl(event.currentTarget);
    
  }, []);

  const popuphandleClose = React.useCallback(() => {
      setAnchorEl(null);
  }
  )



    return (
        <div>
        
        
      
        <ThemeProvider theme={theme}>
        
            <JumboDdPopover 
             handleClick={popuphandleClick}
             handleClose={popuphandleClose}
             isOpen={isOpen}
             anchorEl={anchorEl}
                triggerButton={
                    <div className='d-flex items-center ' style={{cursor: "pointer"}}>
                      <div className='user_details'>
                       <h2 className='username_top'>RamKumar </h2>
                       <p>Admin</p>
                       </div>
                      <Avatar
                      className='avater_user'
                        src="https://getillustrations.b-cdn.net//photos/pack/3d-avatar-male_lg.png"
                        sizes={"small"}
                        
                    />
                    
                    <IoIosArrowDown style={{fontSize:"26px",}} />
                    </div>
                  
                    

                }
            >
                <Div sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    p: theme => theme.spacing(0),
                }}>
                     
                    
                </Div>
                 
                <nav>
                    <List className='user_list' style={{minHeight: "auto", minWidth: "200px"}} >

                        


                        <ListItemButton >
                            <ListItemIcon  sx={{minWidth: 36}}>
                                <SlLock  style={{fontSize:"22px"}}/>
                            </ListItemIcon>
                            <ListItemText  onClick={handleChangePassToggle} primary="Change Password" sx={{my: 0, fontSize:"18px"}}/>
                        </ListItemButton>

                       

                        

                        <ListItemButton onClick={onLogout}>
                            <ListItemIcon sx={{minWidth: 36}}>
                                <AiOutlineLogin style={{fontSize:"22px" , transform: "rotate(175deg)", color: "#e41919",}} />
                            </ListItemIcon>
                            <ListItemText primary="Logout" sx={{my: 0, color: "#e41919", fontSize:"18px"}}/>
                        </ListItemButton>
                    </List>
                </nav>



            </JumboDdPopover>
 
            
       
        <ChangePassword handleDrawerClose={handleChangePassClose} changePassOpen={changePassOpen}  handleDrawerToggle={handleChangePassToggle}   />


        </ThemeProvider>
       
        </div>
    );
};

export default AuthUserDropdown;
