import React from 'react'

import ClearIcon from '@mui/icons-material/Clear';
import { Box, Button, Drawer,  TextField, Typography,  } from '@mui/material';
import Div from '@jumbo/shared/Div';


const SubscrptionsCreate = ( { handleDrawerToggle,
    mobileOpen,
    handleDrawerClose}) => {

        // alert(handleDrawerClose)
  return (
    <Drawer
        variant="temporary"
        anchor={"right"}
        open={mobileOpen}
        onClose={handleDrawerClose}
      >
        <Box className='sidenave_box' sx={{ width: "520px" }}>
          <div className="sidebar_header">
            <h1>Add New Subscription</h1>
            <ClearIcon sx={{ cursor: "pointer" }} onClick={handleDrawerClose} />
          </div>
          <div className="sideform_container">

                    
<form  >
  <Div sx={{ mt: 1 }}>
    <Typography className="from_label">
    SUBSCRIPTION NAME
    </Typography>
    <TextField
      name = ""
     
      className="form_new_style"
      variant="outlined"
      fullWidth
    />
  </Div>
  <Div sx={{ mt: 3 }}>
    <Typography className="from_label">SUBSCRIPTION NUMBER</Typography>
    <TextField
     name = ""
    
      type='number'
      className="form_new_style"
      id="outlined-basic"
      variant="outlined"
      fullWidth
    />
  </Div>
  <Div sx={{ mt: 3 }}>
    <Typography className="from_label">SUBSCRIPTION PRICE (₹)</Typography>
    <TextField
     name = ""
     type='number'
      className="form_new_style price"
      id="outlined-basic"
      variant="outlined"
      fullWidth
    
    />
  </Div>

  <Div sx={{ mt: 3 }}>
    <Button
      className="primary_btn w-360"
      fullWidth
      type="submit"
      variant="contained"
      size="large"
      sx={{ mb: 3 }}
           
    >
      Add Subscription
    </Button>
  </Div>
</form>

</div>

        </Box>
      </Drawer>
  )
}

export default SubscrptionsCreate