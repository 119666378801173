import { SET_USER_DATA, SET_PERMISSION, SET_USER } from "app/utils/constants/appActions";

const INIT_STATE = {
    userData : null,
    permissions : null
}

const permissionReducer = (state = INIT_STATE, action) => {
    switch(action.type) {
        case SET_USER_DATA: {
            return {
                ...state,
                userData: action.payload
            }
        }
        case SET_PERMISSION: {
            return {
                ...state,
                permissions: action.payload
            }
        }
        case SET_USER: {
            return {
                ...state,
                userData: null,
                permissions: null
            }
        }

        default: {
            return state;
        }
    }
}

export default permissionReducer;