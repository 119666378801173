import React from 'react';
import PropTypes from 'prop-types';
import Popover from "@mui/material/Popover";

const JumboDdPopover = ({triggerButton, children, isOpen, anchorEl, handleClick, handleClose}) => {
 
    

 
    return (
        <>
            <span onClick={handleClick}>
                {triggerButton}
            </span>
            <Popover
            id='popover'
            
                open={isOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                sx={{
                    mt: 2,
                    mr: 6
                }}
            >
                {children}
            </Popover>
        </>
    );
};

JumboDdPopover.propTypes = {
    open: PropTypes.bool,
    triggerButton: PropTypes.node,
    children: PropTypes.node,
};

export default JumboDdPopover;
