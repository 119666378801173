import React from "react";
import { useNavigate } from "react-router-dom";
import {Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Div from "@jumbo/shared/Div";
import { SessionSvg, TICK } from "app/assets/svg/Svg";
import { MainLogo } from "app/assets/svg/logo";

const SuccessMessage = () => {
  const navigate = useNavigate();
  function handleClick(event) {
    event.preventDefault();
    navigate("/login", { replace: true });
  }
  return (
    <div className="session_page ">
      <Grid className="h-full session_grid" container>
        <Grid
          className="session_image_content"
          item
          lg={6}
          style={{ alignSelf: "center" }}
        >
          <div className="session_content">
            <div className="session_subcont">
              <h1>Welcome Back!</h1>
              <p>
                Access the <span> administrative features</span> <br /> and
                manage your account with ease.
              </p>
            </div>
            <div className="session_img">
              <SessionSvg />
            </div>
          </div>
        </Grid>
        <Grid item lg={6} style={{ alignSelf: "center" }}>
          <div className="session_card flex_container_col">
            <div className="session_wrapper mb-30">
            <div className="text-center login_logo">
             <MainLogo  />
             </div>
              <div className="session_form">
                <div style={{ marginTop: "60px" }}>
                  <div className="text-center">
                    <TICK />

                    <p className="sucess_greeting">
                      You have <span>successfully reset your password.</span>{" "}
                      Please use your new password when logging in.
                    </p>
                  </div>
                </div>
                <Div className="mt-40">
                  <LoadingButton
                    onClick={handleClick}
                    className="filled_btn"
                    fullWidth
                    type="submit"
                    variant="contained"
                    size="large"
                  >
                    Go to Login{" "}
                  </LoadingButton>
                </Div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default SuccessMessage;
