import React from 'react';
import LOADER from "../../../assets/loading-gif.gif";
import { useEffect } from 'react';

const BackdropLoader = ({isShow}) => {
  
 useEffect(()=>{

  //  if(isShow == true){
  //   document.body.style.overflowY = "hidden"
  //  }
  //  else if (isShow == false) {
  //   document.body.style.overflowY = "unset"
  //  }
  //  else{
  //   window.body.style.overflowY = "unset"
  //  }



 },[])






  return (
    <>
     <div className="loader-container">
      <div className="backdrop-loader">
        {/* Your loader image goes here */}
        <img src={LOADER} alt="Loader Image" />
      </div>
    </div>
    </>
   
  );
};

export default BackdropLoader;