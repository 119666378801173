import React from "react";

import routes from "./routes";
import useJumboRoutes from "@jumbo/hooks/useJumboRoutes";
 

const AppRoutes = () => {
    const appRoutes = useJumboRoutes(routes);
  //  const adminAppRoutes = useJumboRoutes(adminRoutes);
    return (
        <React.Fragment>
           
            { appRoutes  }
            { /* adminAppRoutes */ }
        </React.Fragment>
    );
};
export default AppRoutes;
