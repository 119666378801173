import React, { useState } from 'react';
import { Form, Formik } from "formik";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Div from "@jumbo/shared/Div";
import * as yup from "yup";
import JumboAppTextField from "@jumbo/components/JumboAppTextField";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
import { config } from 'app/config/main';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const validationSchema = yup.object({
    email: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
    password: yup
        .string('Enter your password')
        // .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/g, 'Invalid Password')
        .required('Password is required'),
    name: yup
        .string("Enter your name")
        .required("Name is required"),
    mobileno: yup
        .number("Enter your Phone Number")
        .typeError("Invalid Phone Number")
        .positive("Phone number can't start with a minus")
        .integer("Phone number can't include a decimal point")
        .min(10)
        .required('Phone number is required')
});

const SignupForm = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    
    const onSignup = async (data) => {
        try {
            setLoading(true)
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }
            await fetch(`${config.apiUrl}/register`, options)
            .then(res => res.json())
            .then(res => {
                console.log(res)
                if(res.status === true) {
                    setLoading(false)
                    toast.success(res.message);
                    setTimeout(()=> {
                        navigate('/home')
                    }, 2000)
                } 
                if(res.status === false) {
                    setLoading(false)
                    toast.error(res.message);
                }
            })
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Formik
            validateOnChange={true}
            initialValues={{
                name: '',
                email: '',
                mobileno: '',
                password: '',
            }}
            validationSchema={validationSchema}
            onSubmit={(data, { setSubmitting }) => {
                setSubmitting(true);
                onSignup(data);
                setSubmitting(false);
            }}
        >
            {({ isSubmitting }) => (
                <Form noValidate autoComplete="off">
                    <Fab color="secondary" aria-label="add"
                        sx={{
                            display: 'flex',
                            marginLeft: 'auto',
                            transform: 'translateY(-50%)',
                        }}
                    >
                        <AddIcon />
                    </Fab>
                    <Div sx={{ mb: 3, mt: 1 }}>
                        <JumboAppTextField
                            fullWidth
                            name="name"
                            label="Name"
                        />
                    </Div>
                    <Div sx={{ mb: 3, mt: 1 }}>
                        <JumboAppTextField
                            fullWidth
                            name="email"
                            label="Email"
                        />
                    </Div>
                    <Div sx={{ mb: 3, mt: 1 }}>
                        <JumboAppTextField
                            fullWidth
                            name="mobileno"
                            label="Phone Number"
                        />
                    </Div>
                    <Div sx={{ mb: 2, mt: 1 }}>
                        <JumboAppTextField
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                        />
                    </Div>
                    <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        size="large"
                        sx={{ mb: 3 }}
                        loading={loading}
                    >Signup</LoadingButton>
                </Form>
            )}
        </Formik>
    );
};

export default SignupForm;
