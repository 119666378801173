import React, { Fragment, useState } from 'react';
import Div from "@jumbo/shared/Div";
import { CardContent, CardHeader, Checkbox, FormControlLabel, Card, Typography, Avatar, Grid, Tooltip, IconButton, TablePagination } from "@mui/material";
import { stringAvatar } from 'app/utils/formatHelpers';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import SwapHorizOutlinedIcon from '@mui/icons-material/SwapHorizOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { config } from 'app/config/main';
import moment from 'moment';
import { toast } from 'react-toastify';
import { ToastServices } from 'app/services/toastServices';

const RolesList = () => {
    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);


    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0)
    }

    const token = useSelector(state => state.auth?.accessToken);

    const loadRolesData = async () => {
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        await fetch(`${config.apiUrl}/role/list`, options)
            .then(res => res.json())
            .then(res => {
                console.log(res)
                setData(res.data)
                setCount(res.count)
            })
    }

    const handleStatus = async (id, status) => {
        try {
            const bodyData = {
                id: id,
                status: status === 1 ? 0 : 1
            }
            const options = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(bodyData)
            }
            await fetch(`${config.apiUrl}/role/status`, options)
                .then(res => res.json())
                .then((res) => {
                    console.log(res);
                    if (res.status === true) {
                        toast.success('Role status Updated Successfully')
                        loadRolesData();

                    } else {
                        toast.error(res.message)
                    }
                }, (err) => {
                    console.log(err)
                })
        } catch (error) {
            console.log(error)
        }
    }


    const handleDelete = async (id) => {
        try {
            const options = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }
            await fetch(`${config.apiUrl}/role/delete/${id}`, options)
            .then(res => res.json())
            .then(res => {
                console.log(res);
                if(res.status === true) {
                    ToastServices.SucessToast('Role Deleted Successfully')
                    loadRolesData();
                    
                } else {
                    toast.error(res.message);
                }
            }, (err)=> {
                console.log(err)
            })
        } catch (error) {
            console.log(error)
        }
    }

    const handleCardClick = (i) => {
        navigate(`/settings/roles-permissions/permission-list/${i.id}`)
    }

    useEffect(() => {
        loadRolesData();
    }, [])

    return (
        <Fragment>
            <Div sx={{ display: 'flex' }}>
                <Typography className='page_header' variant={"h1"} mb={2}>{`Roles & Permissions (${count == undefined ? "0" : count})`}</Typography>

            </Div>
            <Div className='roles'>
                {
                    data?.map((i) => {
                        return (
                            <Grid xs='10' key={i}>
                                <Card className=' br-0' sx={{
                                    ':hover': {
                                        cursor: 'pointer',
                                        backgroundColor: '#F5F7FA'
                                    }
                                }}>
                                    <CardContent>
                                        <div className='d-flex roles'>
                                            <div className='d-flex role-set' onClick={()=>handleCardClick(i)}>
                                                <Avatar {...stringAvatar(i.name)} />
                                                <div className='pl-1'>
                                                    <div className='d-flex div-height'>
                                                        <p className='avatar-p'>{i.name}</p> {i.status === 1 ? <span className='admin_active_status ml-10'><span className='active_dot'></span> Active</span> : <span className='admin_inactive_status ml-10'><span className='inactive_dot'></span>Inactive</span>}
                                                    </div>
                                                    <p>Admin created this role on {moment(i.createdAt).format('DD-MM-YYYY')}</p>
                                                </div>
                                            </div>
                                            <div className='d-flex action-set items-center'>
                                                <Tooltip title="Edit">
                                                    <IconButton onClick={()=>handleCardClick(i)}>
                                                        <ModeEditOutlineOutlinedIcon sx={{
                                                            color: '#00000085',
                                                            ':hover': {
                                                                color: '#f49d00'
                                                            }
                                                        }} />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Change Status">
                                                    <IconButton onClick={() => handleStatus(i.id, i.status)}>
                                                        <SwapHorizOutlinedIcon sx={{
                                                            color: '#00000085',
                                                            ':hover': {
                                                                color: '#f49d00'
                                                            }
                                                        }} />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Delete">
                                                    <IconButton onClick={() => handleDelete(i.id)}>
                                                        <DeleteOutlineOutlinedIcon sx={{
                                                            color: '#00000085',
                                                            ':hover': {
                                                                color: '#E53935'
                                                            }
                                                        }} />
                                                    </IconButton>
                                                </Tooltip>

                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>
                        )
                    })
                }
            </Div>
            <TablePagination
                count={count}
                page={page}
                onPageChange={(event, newPage) => setPage(newPage)}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Fragment>
    )
}

export default RolesList;