import VerticalDefault from "../../layouts/vertical-default/VerticalDefault";
import SoloPage from "../../layouts/solo-page/SoloPage";
import AdminLayout from "app/layouts/admin-layout/SoloPage";

const LAYOUT_NAMES = {
    VERTICAL_DEFAULT: "vertical-default",
    SOLO_PAGE: "solo-page",
    ADMIN_LAYOUT : "admin-layout"
};

const LAYOUTS = [
    {
        name: LAYOUT_NAMES.VERTICAL_DEFAULT,
        label: "Vertical Default",
        component: VerticalDefault,
    },
    {
        name: LAYOUT_NAMES.SOLO_PAGE,
        label: "Solo Page",
        component: SoloPage
    },
    {
        name: LAYOUT_NAMES.ADMIN_LAYOUT,
        label: "admin page",
        component: AdminLayout
    }
];

export {LAYOUTS, LAYOUT_NAMES};