import Div from "@jumbo/shared/Div";
import {
  TableCell,
  TableRow,
 } from "@mui/material";
import React, { useState , useEffect} from "react";

import Skeleton from '@mui/material/Skeleton';

const SkeletonTableLoader = ({tablecell, row }) => {
  
  const rows = Array.from({ length: row }, (_, index) => (
    <TableRow key={index}>
    {Array.from({ length: tablecell }, (_, cellIndex) => (
      <TableCell key={cellIndex}> <Skeleton variant="text" sx={{ fontSize: '1rem' }} />  </TableCell>
    ))}
    </TableRow>
  ));

  return <>{rows}</>;
  }
export default SkeletonTableLoader;
