import { SET_NOTIFICATION } from "app/utils/constants/appActions";

const INIT_STATE = {
    data: null
}

const notifyReducer = (state = INIT_STATE, action) => {
    switch(action.type) {
        case SET_NOTIFICATION: {
            return {
                ...state,
                data: action.payload
            }
        }

        default: {
            return state;
        }
    }
}

export default notifyReducer;