import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { Form, Formik } from "formik";
import {
 Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";
import Link from "@mui/material/Link";
import LoadingButton from "@mui/lab/LoadingButton";
import Div from "@jumbo/shared/Div";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { SessionSvg } from "app/assets/svg/Svg";
import { MainLogo } from "app/assets/svg/logo";


const validationSchema = yup.object({
  npassword: yup.string("Enter your password").required("Password is required"),
  cpassword: yup
    .string("Enter your confirm password")
    .required("Confirm password is required"),
});

const SetNewPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [newshowPassword, setNewshowPassword] = useState(false);
  const onSignIn = async (data) => {
    setLoading(true);
    navigate("/success-message", { replace: true });
    setLoading(false);
  };


  return (
    <div>
      <div className="session_page ">
        <Grid className="h-full session_grid" container>
          <Grid
            className="session_image_content"
            item
            lg={6}
            style={{ alignSelf: "center" }}
          >
            <div className="session_content">
              <div className="session_subcont">
                <h1>Welcome Back!</h1>
                <p>
                  Access the <span> administrative features</span> <br /> and
                  manage your account with ease.
                </p>
              </div>
              <div className="session_img">
                <SessionSvg />
              </div>
            </div>
          </Grid>
          <Grid item lg={6} style={{ alignSelf: "center" }}>
            <div className="session_card flex_container_col">
              <div className="session_wrapper mb-30">
              <div className="text-center login_logo">
             <MainLogo  />
             </div>
                <div className="session_form">
                  <div className="session_title">
                    <h2>Set New Password</h2>
                    <p>Must be at least 8 characters</p>
                  </div>
                  <div className="mt-40">
                    <Formik
                      validateOnChange={true}
                      initialValues={{
                        npassword: "111111111111111",
                        cpassword: "111111111111111",
                      }}
                      validationSchema={validationSchema}
                      onSubmit={(data, { setSubmitting }) => {
                        setSubmitting(true);
                        onSignIn(data);
                        setSubmitting(false);
                      }}
                    >
                      {({ isSubmitting }) => (
                        <Form
                          style={{ textAlign: "left" }}
                          noValidate
                          autoComplete="off"
                        >
                          <Div className="login_form_input responisve">
                            <Typography className="login_label">
                              Password
                            </Typography>
                            <OutlinedInput
                              name="npassword"
                              className="password_input"
                              placeholder="Enter Password"
                              fullWidth={true}
                              id="outlined-adornment-password"
                              type={showPassword ? "text" : "password"}
                              required={true}
                              //onChange={handleChange}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() =>
                                      setShowPassword((show) => !show)
                                    }
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                          </Div>
                          <Div className="login_form_input responisve">
                            <Typography className="login_label">
                              Confirm Password
                            </Typography>
                            <OutlinedInput
                              name="cpassword"
                              className="password_input"
                              placeholder="Enter Confirm Password"
                              fullWidth={true}
                              id="outlined-adornment-password"
                              type={newshowPassword ? "text" : "password"}
                              required={true}
                              //onChange={handleChange}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() =>
                                      setNewshowPassword((show) => !show)
                                    }
                                    edge="end"
                                  >
                                    {newshowPassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                          </Div>
                          <Div className="mt-40">
                            <LoadingButton
                              className="login_btn responisve"
                              fullWidth
                              type="submit"
                              variant="contained"
                              size="large"
                              loading={loading}
                            >
                              Set New Password
                            </LoadingButton>
                          </Div>

                          <Typography sx={{ mt: 4 }} textAlign={"center"}>
                            <Link
                              className="forget_link responisve"
                              underline="none"
                              href="/"
                            >
                              {" "}
                              Back to Login{" "}
                            </Link>
                          </Typography>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default SetNewPassword;
