import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import contactsApp from "./contactsApp";
import authReducer from './auth';
import notifyReducer from './notification';
import permissionReducer from './permissions';

const exportReducers = history => {
    return combineReducers({
        router: connectRouter(history),
        contactsApp: contactsApp,
        auth: authReducer,
        notification: notifyReducer,
        user: permissionReducer
    });
};

export default exportReducers;

