import { LOGGED_IN, LOGOUT } from "app/utils/constants/authActions";

const INIT_STATE = {
    auth: false,
    accessToken: null
}

const authReducer = (state = INIT_STATE, action) => {
    switch(action.type) {
        case LOGGED_IN: {
            return{
                ...state,
                auth: true,
                accessToken: action.payload
            }

        }

        case LOGOUT: {
            return {
                ...state,
                auth: false,
                accessToken: null
            }
        }
        default: {
            return state;
        }
    }
}

export default authReducer