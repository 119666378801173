import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import reducers from '../reducers';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);

const bindMiddleware = middleware => {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    return composeEnhancers(applyMiddleware(...middleware));
};

const persistConfig = {
    key: 'root',
    storage,
    // Optionally, you can configure which parts of the state to persist
    // whitelist: ['user'], // Only 'user' state will be persisted
    // blacklist: ['auth'], // 'auth' state will not be persisted
};
  
// function configureStore(initialState = {}) {
//     const store = createStore(reducers(history), initialState, bindMiddleware([routeMiddleware, thunk]));
//     if (module.hot) {
//         // Enable Webpack hot module replacement for reducers
//         module.hot.accept('../reducers/index', () => {
//             const exportReducers = require('../reducers');
//             store.replaceReducer(exportReducers);
//         });
//     }
//     return store;
// }

const initialState = {}

const persistedReducer = persistReducer(persistConfig, reducers(history));

const store = createStore(persistedReducer, initialState, bindMiddleware([routeMiddleware, thunk]));
const persistor = persistStore(store);

persistor.subscribe(() => {
    const isPersisted = persistor.getState();
    // console.log('State persisted:', isPersisted);
  });

export { history, store, persistor };
