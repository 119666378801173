import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";
import { Grid, TextField, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import LoadingButton from "@mui/lab/LoadingButton";
import Div from "@jumbo/shared/Div";
import { SessionSvg } from "app/assets/svg/Svg";
import { MainLogo } from "app/assets/svg/logo";

const otpValidation = yup
  .array()
  .of(
    yup
      .string()
      .matches(/^\d+$/, "Each PIN digit should be a digit")
      .length(1, "Each PIN digit must be exactly 1 digit")
  )
  .required("PIN is required")
  .test(
    "len",
    "PIN must be exactly 6 digits",
    (val) => val && val.join("").length === 6
  );

const validationSchema = yup.object({
  otp: otpValidation,
});

const ResetPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const inputRefs = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ];

  const handleInputChange = (e, index, formik, fieldName, valueArray, refs) => {
    const inputValue = e.target.value;
    const newArray = [...valueArray];

    if (inputValue.match(/^\d+$/)) {
      newArray[index] = inputValue;
      formik.setFieldValue(fieldName, newArray);
      if (index < 5 && inputValue) {
        refs[index + 1].current.focus();
      }
    } else if (inputValue === "" && index > 0) {
      newArray[index] = inputValue;
      formik.setFieldValue(fieldName, newArray);
      refs[index - 1].current.focus();
    } else if (inputValue === "" && index === 0) {
      newArray[index] = inputValue;
      formik.setFieldValue(fieldName, newArray);
    }
  };

  const formik = useFormik({
    initialValues: {
      otp: Array.from({ length: 6 }, () => ""),
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      // Handle form submission logic here
      console.log("Form submitted:", values);
      navigate("/set-password", { replace: true });
      setLoading(false);
    },
  });
  const [otp, setOtp] = useState("");
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(10);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const resendOTP = () => {
    setMinutes(0);
    setSeconds(10);
  };
  return (
    <div className="session_page ">
      <Grid className="h-full session_grid" container>
        <Grid
          className="session_image_content"
          item
          lg={6}
          style={{ alignSelf: "center" }}
        >
          <div className="session_content">
            <div className="session_subcont">
              <h1>Welcome Back!</h1>
              <p>
                Access the <span> administrative features</span> <br /> and
                manage your account with ease.
              </p>
            </div>
            <div className="session_img">
              <SessionSvg />
            </div>
          </div>
        </Grid>
        <Grid item lg={6} style={{ alignSelf: "center" }}>
          <div className="session_card flex_container_col">
            <div className="session_wrapper mb-30">
            <div className="text-center login_logo">
             <MainLogo  />
             </div>
              <div className="session_form">
                <div className="session_title">
                  <h2>Reset Password</h2>
                  <p>We sent a code to vinothini@gmail.com</p>
                </div>
                <div className="mt-40">
                  <form
                    onSubmit={formik.handleSubmit}
                    noValidate
                    autoComplete="off"
                  >
                    <Typography className="login_label responisve">
                      Enter Code
                    </Typography>
                    <div className="Otp_input_box emailcode responisve">
                      {formik.values.otp.map((_, index) => (
                        <div key={index} className="otp_input small">
                          <TextField
                            className="otp_fieled"
                            name={`otp[${index}]`}
                            variant="outlined"
                            margin="normal"
                            type="text"
                            inputProps={{
                              maxLength: 1,
                            }}
                            inputRef={inputRefs[index]}
                            error={
                              formik.touched.otp && formik.errors.otp
                                ? true
                                : false
                            }
                            onChange={(e) =>
                              handleInputChange(
                                e,
                                index,
                                formik,
                                "otp",
                                formik.values.otp,
                                inputRefs
                              )
                            }
                          />
                        </div>
                      ))}
                    </div>
                    {formik.touched.otp && formik.errors.otp && (
                      <Div className="error text-danger">
                        {formik.errors.otp}
                      </Div>
                    )}
                    <Typography sx={{ mt: 1 }} textAlign={"right"}>
                      {seconds > 0 || minutes > 0 ? (
                        <Link className="forget_link forgot responisve cursor-pointer">
                          Time Remaining:{" "}
                          {minutes < 10 ? `0${minutes}` : minutes}:
                          {seconds < 10 ? `0${seconds}` : seconds}
                        </Link>
                      ) : (
                        <Link
                          className="forget_link forgot responisve cursor-pointer"
                          onClick={resendOTP}
                        >
                          Resend Code
                        </Link>
                      )}
                    </Typography>

                    <LoadingButton
                      className="login_btn mt_80 responisve"
                      fullWidth
                      type="submit"
                      variant="contained"
                      size="large"
                      sx={{ mb: 3 }}
                      loading={loading}
                    >
                      Continue
                    </LoadingButton>

                    <Typography textAlign={"center"}>
                      <Link
                        className="forget_link responisve"
                        underline="none"
                        href="/"
                        sx={{ color: "#624FBA !important" }}
                      >
                        {" "}
                        Back to Login{" "}
                      </Link>
                    </Typography>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ResetPassword;
