import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  InputAdornment,
  OutlinedInput,
  Pagination,
  Select,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Tabs,
  Tooltip,
  TableSortLabel
} from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Div from "@jumbo/shared/Div";
import { ToastServices } from "app/services/toastServices";
import DyanamicDatePicker from "../../component/DatePicker/DyanamicDatePicker";
import { CiSearch } from "react-icons/ci";
import { EyeIcon, FilterIcon, Premium, RefreshIcon } from "app/assets/svg/Svg";
const SubscriptionsList = () => {
  const TableData = [
    {
      userid: "923738",
      user: "Mark",
      userImage:
        "https://htmlstream.com/preview/unify-v2.6/assets/img-temp/400x450/img5.jpg",
      memberIcon: true,
      planType: "Base",
      duration: "3 months",
      planPrice: "₹ 130.00",
      startDate: "24-01-2024",
      startTime: "(09:23:33 AM)",
      EndDate: "12-12-2024",
      EndTime: "(09:23:33 AM)",
      status: 1,
    },
    {
      userid: "356421",
      user: "Steve Austin",
      userImage:
        "https://htmlstream.com/preview/unify-v2.6/assets/img-temp/400x450/img5.jpg",
      memberIcon: false,
      planType: "Gold",
      duration: "9 months",
      planPrice: "₹ 600.00",
      startDate: "24-01-2024",
      startTime: "(09:23:33 AM)",
      EndDate: "12-12-2024",
      EndTime: "(09:23:33 AM)",
      status: 2,
    },
    {
      userid: "923745",
      user: "Lilly",
      userImage:
        "https://htmlstream.com/preview/unify-v2.6/assets/img-temp/400x450/img5.jpg",
      memberIcon: true,
      planType: "Platinum",
      duration: "8 months",
      planPrice: "₹ 1130.00",
      startDate: "24-01-2024",
      startTime: "(09:23:33 AM)",
      EndDate: "12-12-2024",
      EndTime: "(09:23:33 AM)",
      status: 0,
    },
  ];

  const [isModalOpen, setModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const shoToast = () => {
    ToastServices.ErroToast("Test toaster");
    ToastServices.SucessToast("Test toaster");
    ToastServices.WarnToast("Test toaster");
    ToastServices.InfoToast("Test toaster");
  };

  const [page, setPage] = React.useState(10);

  const handleChangePage = (event) => {
    setPage(event.target.value);
  };
  const [value, setValue] = React.useState(0);
  const tabhandleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <section className="main_section">
      <Box>
        <Box>
        <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Box>
            <Box>
              <Tabs
                
                className="custom-tabs-2"
                value={value}
                onChange={tabhandleChange}
                aria-label="basic tabs example"
              >
                <Tab label={`All Users (300)`} {...a11yProps(0)} />
                <Tab label={`Normal Users (182)`} {...a11yProps(1)} />
                <Tab label={`Prime Users (118)`} {...a11yProps(2)} />
              </Tabs>
            </Box>
          </Box>
        </Grid>
        
      </Grid>
        </Box>
      </Box>

      <div className="page_header_section">
        <Grid container spacing={1}>
          <Grid item lg={1} xl={1} className="d-flex gap-5">
            <Tooltip title="Reset">
              {" "}
              <IconButton className="icon_btn" variant="outlined">
                {" "}
                <RefreshIcon />{" "}
              </IconButton>
            </Tooltip>
            <Tooltip title="Filter">
              {" "}
              <IconButton className="icon_btn" variant="outlined">
                {" "}
                <FilterIcon />{" "}
              </IconButton>
            </Tooltip>
          </Grid>

          <Grid item lg={5} xl={4.5} md={10}>
            <div className="date_pic flex-1 ml-5">
              <DyanamicDatePicker />
            </div>
          </Grid>
          <Grid item lg={4} xl={4.5} md={10}>
            <OutlinedInput
              className="search_bar w-100 ml-10"
              id="outlined-adornment-password"
              placeholder="Search by Keyword"
              startAdornment={
                <InputAdornment position="start">
                  <IconButton edge="start">
                    <CiSearch />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>

          <Grid item lg={2} xl={2}>
            <Button className="outlined_btn  ml-10" variant="outlined" onClick={shoToast}>
              {" "}
              Export{" "}
            </Button>
          </Grid>
        </Grid>
      </div>

      <Card className="responsivecard">
        <CardContent>
          <Div>
            <Table className="common_table" id="tablePopover">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel>User Id</TableSortLabel>{" "}
                  </TableCell>
                  <TableCell>
                    <TableSortLabel>User</TableSortLabel>{" "}
                  </TableCell>
                  <TableCell>
                    <TableSortLabel>Plan Type</TableSortLabel>{" "}
                  </TableCell>
                  <TableCell>
                    <TableSortLabel>Duration</TableSortLabel>{" "}
                  </TableCell>
                  <TableCell className="amt_head">
                    <TableSortLabel>Price (₹)</TableSortLabel>{" "}
                  </TableCell>
                  <TableCell>
                    <TableSortLabel>Start Date</TableSortLabel>{" "}
                  </TableCell>
                  <TableCell>
                    <TableSortLabel>End Date</TableSortLabel>{" "}
                  </TableCell>
                  <TableCell>
                    <TableSortLabel>Status</TableSortLabel>
                  </TableCell>

                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {TableData.map((item, index) => {
                  return (
                    <TableRow key={item}>
                      <TableCell>{item?.userid}</TableCell>
                      <TableCell>
                        <div className="d-flex items-center g-18">
                          <div className="userImage_table">
                            <img src={item?.userImage} alt="profile image" />
                            <span
                              className={
                                item?.memberIcon === true
                                  ? "d-block premiumicon"
                                  : "d-none"
                              }
                            >
                              <Premium />
                            </span>
                          </div>
                          {item?.user}
                        </div>
                      </TableCell>
                      <TableCell>
                        {" "}
                        <span
                          className={
                            item?.planType === "Base"
                              ? "baseplan"
                              : item?.planType === "Gold"
                              ? "goldplan"
                              : item?.planType === "Platinum"
                              ? "plantinumplan"
                              : ""
                          }
                        >
                          {item?.planType}
                        </span>{" "}
                      </TableCell>
                      <TableCell>{item?.duration}</TableCell>
                      <TableCell className="amt_cell">
                        {item?.planPrice}
                      </TableCell>
                      <TableCell>
                        {item?.startDate} <br /> {item?.startTime}{" "}
                      </TableCell>
                      <TableCell>
                        {item?.EndDate} <br /> {item?.EndTime}{" "}
                      </TableCell>

                      <TableCell>
                        {item?.status === 1 ? (
                          <span className="status-active">Active</span>
                        ) : item?.status === 0 ? (
                          <span className="status-inactive">Inactive</span>
                        ) : item?.status === 2 ? (
                          <span className="status-expired ">Blocked</span>
                        ) : (
                          ""
                        )}
                      </TableCell>
                      <TableCell>
                        <div>
                          <Tooltip title="View">
                            {" "}
                            <IconButton
                              className="svgtable_icon_btn"
                              variant="outlined"
                            >
                              {" "}
                              <EyeIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip
                            id="basic-button"
                            aria-controls={open ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            onClick={handleClick}
                            title="More"
                          >
                            {" "}
                            <IconButton
                              className="svgtable_icon_btn"
                              variant="outlined"
                            >
                              {" "}
                              <MoreVertIcon />
                            </IconButton>
                          </Tooltip>
                          <Menu
                            className="custom_popover"
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                          >
                            <MenuItem onClick={handleClose}>Menu 1</MenuItem>
                            <MenuItem onClick={handleClose}>Menu 2</MenuItem>
                            <MenuItem onClick={handleClose}>Menu 3</MenuItem>
                          </Menu>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={100} style={{ padding: "0" }}>
                    <div className="table_pagination">
                      <div className="items_per_page">
                        <Select
                          className="pagination_select"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={page}
                          onChange={handleChangePage}
                        >
                          <MenuItem value={10}>10</MenuItem>
                          <MenuItem value={20}>20</MenuItem>
                          <MenuItem value={30}>30</MenuItem>
                        </Select>
                        <p>Items per Page</p>
                      </div>

                      <Stack spacing={2}>
                        <Pagination count={10} />
                      </Stack>
                    </div>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </Div>
        </CardContent>
      </Card>
    </section>
  );
};

export default SubscriptionsList;
