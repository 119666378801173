import React, { Fragment, useState, useEffect } from 'react';
import Div from "@jumbo/shared/Div";
import { CardContent, CardHeader, Checkbox, FormControlLabel, Card, Typography, Chip, TableFooter } from "@mui/material";
import { Table, TableBody, TableCell, TableHead, TableRow, TablePagination } from "@mui/material";
import { useSelector } from 'react-redux';
import { config } from 'app/config/main';
import moment from 'moment';
import { toast } from 'react-toastify';


const NotificationList = () => {
    const token = useSelector(state => state.auth?.accessToken);
    const notifyData = useSelector(state => state.notification?.data)

    const [data, setData] = useState(notifyData);

    const handleMarkasRead = async () => {
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        await fetch(`${config.apiUrl}/push/mark-all-read`, options)
        .then(res => res.json())
        .then((res) => {
            console.log(res)
        }, (err)=>{
            console.log(err)
        })
    }

    useEffect(()=>{
        handleMarkasRead();
    }, [])
    
    return (
        <Fragment>
            <Typography variant={"h1"} mb={5}>{'Notification List'}</Typography>
            <Div>
                <Card>
                    <CardContent>
                        <Div>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Title</TableCell>
                                        <TableCell>Description</TableCell>
                                        <TableCell>Created On</TableCell>
                                        <TableCell>Status</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        data?.map((i)=> {
                                            
                                            return(
                                                <TableRow>
                                                    <TableCell>{i.module_name}</TableCell>
                                                    <TableCell>{i.body}</TableCell>
                                                    <TableCell>{moment(i.createdAt).format('DD-MM-YYYY hh:mm A')}</TableCell>
                                                    <TableCell>{i.msg_read === 1 ? 'Read' : 'Unread'}</TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </Div>
                    </CardContent>
                </Card>
            </Div>
        </Fragment>
    )
}

export default NotificationList;