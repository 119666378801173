export const APP_ACTIONS = {
    SET_CUSTOMIZER_VISIBILITY: "set-customizer-visibility",
    SET_CONTAINER_STYLE: "set-container-style",
    SET_APP: "set-app",
};

export const SET_NOTIFICATION = 'SET_NOTIFICATION';

export const SET_USER_DATA = 'SET_USER_DATA';

export const SET_PERMISSION = 'SET_PERMISSION';

export const SET_USER = 'SET_USER';