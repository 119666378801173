import React from "react"
import {  toast } from 'react-toastify';
import { FaCheck } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { PiShieldWarning } from "react-icons/pi";
import 'react-toastify/dist/ReactToastify.css';
const SucessToast = (alertdata) =>{
  
    toast.success(alertdata ,{
        position:"top-right",
        autoClose: 20000000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        icon :<FaCheck style={{color:"#fff", fontSize:"16px"}} />
    })
    
  return SucessToast
}

const ErroToast = (alertdata) =>{
    toast.error(alertdata ,{
        position:"top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        icon :<IoClose style={{color:"#fff", fontSize:"16px"}} />
    })
  return ErroToast
}
const InfoToast = (alertdata) =>{
    toast.info(alertdata ,{
        position:"top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        icon :<AiOutlineInfoCircle style={{color:"#fff", fontSize:"16px"}} />
    })
  return InfoToast
}
const WarnToast = (alertdata) =>{
    toast.warn(alertdata ,{
        position:"top-right",
        autoClose: 2000000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        icon :<PiShieldWarning style={{color:"#fff", fontSize:"16px"}} />,
        
    })
  return WarnToast
}

export const  ToastServices ={ SucessToast , ErroToast , InfoToast, WarnToast}