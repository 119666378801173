// AuthenticationContext.js
import { ToastServices } from 'app/services/toastServices';
import { createContext, useEffect, useState } from 'react';
// import { jwtDecode } from "jwt-decode";

export const AuthenticationContext = createContext();

export const AuthenticationProvider = ({ children }) => {

  const verifyJWTFromSessionStorage = () => {
 
    const jwt = sessionStorage.getItem('token');
    return jwt ? true : false;
  };

  const [isAuthenticated, setIsAuthenticated] = useState(verifyJWTFromSessionStorage());
 
  useEffect(()=>{
    setIsAuthenticated(verifyJWTFromSessionStorage())

    return () =>{
      setIsAuthenticated(false)
    }
  },[])


 



  const login = () => {
    setIsAuthenticated(true);
    console.log(isAuthenticated , "isAuthenticated")
    ToastServices.SucessToast("Test toaster")
   
  };

  const selogout = () => {
    setIsAuthenticated(false);

  };



 



return (
    <AuthenticationContext.Provider value={{ isAuthenticated , login, selogout ,   }}>
      {children}
    </AuthenticationContext.Provider>
  );
};
