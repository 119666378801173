import React, { Fragment, useState } from 'react';
import { Card, CardContent, IconButton,  Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Div from "@jumbo/shared/Div";
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import BlockIcon from '@mui/icons-material/Block';
import {  useFormik} from 'formik';
import ConfirmModal from './ConfirmModal';
import * as yup from 'yup';

const validationSchema = yup.object({
    oldPass: yup.string()
    .required('Old password is required')
    .min(6, 'Old password must be at least 6 characters'),
    newPass: yup.string()
    .required('New password is required')
    .min(6, 'New password must be at least 6 characters')
    .matches(
      /^(?=.*\d)(?=.*[A-Z])(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]+$/,
      'Must contain at least one digit, one uppercase letter, and one symbol'
    ),
    confirmPass: yup.string()
    .required('Confirm password is required')
    .oneOf([yup.ref('newPass'), null], 'Passwords must match'),
  });
const ChangePassword = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [newPassword, setNewPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState(false);
    const [open, setOpen] = useState(false);
    const [data, setData] = useState();
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

   
  

    const formik = useFormik({
        initialValues: {
          oldPass:'',
          newPass:'',
          confirmPass:''
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
          // Handle form submission logic here
          console.log('Form submitted:', values);
          
        },
      });
    return (
        <Fragment>
            <Typography variant={"h1"} mb={5}>{'Change Password'}</Typography>
            {open && <ConfirmModal value={open} setOpenModal={setOpen} data={data} />}
            <Card sx={{
                maxWidth: 600
            }}>
                <CardContent>
                   
                                <form onSubmit={formik.handleSubmit} noValidate autoComplete='off'>
                                    <Div>
                                        <Div sx={{ mb: 3, mt: 1 }}>
                                            <FormControl sx={{ m: 1, width: 500 }} variant="outlined">
                                                <InputLabel htmlFor="outlined-adornment-password">Old Password<span className='text-danger'>*</span></InputLabel>
                                                <OutlinedInput
                                                name="oldPass"
                                                    fullWidth={true}
                                                    id="outlined-adornment-password"
                                                    type={showPassword ? 'text' : 'password'}
                                                    required={true}
                                                    //onChange={handleChange}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={() => setShowPassword((show) => !show)}
                                                                onMouseDown={handleMouseDownPassword}
                                                                edge="end"
                                                            >
                                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    label="Old Password"
                                                    
                                                />
                                            </FormControl>
                                        </Div>
                                        <Div sx={{ mb: 3, mt: 1 }}>
                                            <FormControl sx={{ m: 1, width: 500 }} variant="outlined">
                                                <InputLabel htmlFor="outlined-adornment-password">New Password<span className='text-danger'>*</span></InputLabel>
                                                <OutlinedInput
                                                 name="newPass"
                                                    fullWidth={true}
                                                    id="outlined-adornment-password"
                                                    type={newPassword ? 'text' : 'password'}
                                                    required={true}
                                                   // onChange={handleChange}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={() => setNewPassword((show) => !show)}
                                                                onMouseDown={handleMouseDownPassword}
                                                                edge="end"
                                                            >
                                                                {newPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    label="New Password"
                                                 
                                                />
                                            </FormControl>
                                        </Div>
                                        <Div sx={{ mb: 3, mt: 1 }}>
                                            <FormControl sx={{ m: 1, width: 500 }} variant="outlined">
                                                <InputLabel htmlFor="outlined-adornment-password">Confirm Password<span className='text-danger'>*</span></InputLabel>
                                                <OutlinedInput
                                                name="confirmPass"
                                                    fullWidth={true}
                                                    id="outlined-adornment-password"
                                                    type={confirmPassword ? 'text' : 'password'}
                                                    required={true}
                                                    //onChange={handleChange}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={() => setConfirmPassword((show) => !show)}
                                                                onMouseDown={handleMouseDownPassword}
                                                                edge="end"
                                                            >
                                                                {confirmPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    label="Confirm Password"
                                                  
                                                />
                                            </FormControl>
                                        </Div>
                                        <Div sx={{
                                            display: 'flex',
                                            // marginLeft: 'auto',
                                            marginTop: '1.5rem',
                                            justifyContent: 'end'
                                        }}>
                                            <Button variant="outlined" sx={{
                                                marginBottom: '2.5rem',
                                                borderRadius: '24px',
                                                backgroundColor: '#B0BEC5',
                                                color: '#000000DE',
                                                textTransform: 'capitalize',
                                                ':hover': {
                                                    backgroundColor: '#B0BEC5',
                                                    color: '#000000DE',
                                                }
                                            }} >Cancel <BlockIcon sx={{ marginLeft: '5px', width: '15px', height: '15px' }} /></Button>
                                            <Button sx={{
                                                marginBottom: '2.5rem',
                                                borderRadius: '24px',
                                                
                                                backgroundColor: '#e48b1a',
                                                color: '#fff',
                                                marginLeft: '1rem',
                                                textTransform: 'capitalize',
                                                ':hover': {
                                                    backgroundColor: '#e48b1a',
                                                    color: '#fff',
                                                }
                                            }} 
                                            type='submit'>
                                                 
                                                Change Password
                                                </Button>
                                        </Div>
                                    </Div>
                                </form>
                           

                </CardContent>
            </Card>
        </Fragment>
    )
}

export default ChangePassword;