import React, { useState } from "react";
import Div from "@jumbo/shared/Div";
import { 
  Card,
  CardContent,
  Grid,
  Pagination,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead, 
  TableRow,
  Typography,
  Button, 
  Menu, 
  MenuItem, 
  IconButton, 
  TableSortLabel, 
  InputAdornment, 
  OutlinedInput, 
  Tabs, 
  Tab, 
  Box, 
  Tooltip
} from "@mui/material";
import PropTypes from "prop-types"; 
import DeletePopup from "../component/deleteModal"; 
import BackdropLoader from "../component/loader";
import SkeletonTableLoader from "../component/skeleton_loader/skeletonTableLoader"; 
import NoRecordsFound from "../component/noRecordsFound";
import SubscrptionsCreate from "../Subscriptions/SubscrptionCreate/SubscrptionsCreate"; 
import DyanamicDatePicker from "../component/DatePicker/DyanamicDatePicker";
import { CiSearch } from "react-icons/ci";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { FilterIcon, RefreshIcon, EyeIcon, PremiumIcon} from "app/assets/svg/Svg"; 
import img1 from "../../../assets/user_img/img1.png";
import img2 from "../../../assets/user_img/img2.png";
import img3 from "../../../assets/user_img/img3.png";
import img4 from "../../../assets/user_img/img4.png"; 


const ReportingUser = () => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [value1, setValue1] = React.useState(0);
  const tab2handleChange = (event, newValue) => {
    setValue1(newValue);
  };

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }
  function handleDrawerClose() {
    setMobileOpen(false);
  }
   
  
  const TableData = [
    {
      userid: "923738",
      created_at: "24-06-2023",
      name: "Thanasekaran",
      feedback: "He behave badly",
      gender: "Male",
      dob: "23-06-1999",
      status: 1,
      premium: 1,
      img: img1,
      reporter_img: img3,
      reporter_name: "Meenatchi",
      reporter_premium: 1,
    },
    {
      userid: "143738",
      created_at: "24-06-2023",
      name: "Kamali",
      feedback: "Unwanted text",
      gender: "Female",
      dob: "11-09-1997",
      status: 0,
      premium: 0,
      img: img2,
      reporter_img: img4,
      reporter_name: "Thanasekaran",
      reporter_premium: 1,
    },
    {
      userid: "583738",
      created_at: "24-06-2023",
      name: "David Raj",
      feedback: "Harassing",
      gender: "Male",
      dob: "23-06-1986",
      status: 1,
      premium: 1,
      img: img4,
      reporter_img: img2,
      reporter_name: "kamali",
      reporter_premium: 0,
    },
    {
      userid: "163738",
      created_at: "23-06-2023",
      name: "Meenatchi",
      feedback: "He behave badly",
      gender: "Female",
      dob: "22-06-1998",
      status: 2,
      premium: 0,
      img: img3,
      reporter_img: img1,
      reporter_name: "David Raj",
      reporter_premium: 1,
    },
  ];

  const terms = ["name", "bank", "acno", "ifsc", "mobile", "actype"];

  const [filteredData, setFilteredData] = useState(TableData);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
 
  const [isModalOpen, setModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setLoader(false);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDelete = () => {
    setAnchorEl(null);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  
  const [page, setPage] = React.useState(10);

  const handleChangePage = (event) => {
    setPage(event.target.value);
  };

  return (
    <section className="current_beneficiaty">
      {" "}
      <DeletePopup open={isModalOpen} handleClose={handleCloseModal} />
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>

           {/********************* All users tab ******************** */}

          
          <Box>
            <Box>
              <Tabs
                indicatorColor="secondary"
                className="custom-tabs-2"
                value={value1}
                onChange={tab2handleChange}
                aria-label="basic tabs example"
              >
                <Tab label={`All Users (300)`} {...a11yProps(0)} />
                <Tab label={`Active Users (182)`} {...a11yProps(1)} />
                <Tab label={`Inactive Users (118)`} {...a11yProps(2)} />
                <Tab label={`Blocked Users (118)`} {...a11yProps(2)} />
              </Tabs>
            </Box>
          </Box>
          
        </Grid>
        
      </Grid>

         {/********************* second block icons, datepicker, search bar ,export button ******************** */}
      <div className="d-flex items-center flex-wrap  mt-30 mb-25">
        <Tooltip title="SVG icon Refresh">
          {" "}
          <IconButton className=" icon_btn  " variant="outlined">
            {" "}
            <RefreshIcon />{" "}
          </IconButton>
        </Tooltip>
        <Tooltip title="SVG icon Filter">
          {" "}
          <IconButton className="ml-15 icon_btn " variant="outlined">
            {" "}
            <FilterIcon />{" "}
          </IconButton>
        </Tooltip>

        <div className="report ml-15">
          <DyanamicDatePicker />
        </div>
        <OutlinedInput
          className="search_bar ml-15"
          id="outlined-adornment-password"
          placeholder="Search by Keyword"
          sx={{ minWidth: "440px !important" }}
          startAdornment={
            <InputAdornment position="start">
              <IconButton edge="start">
                <CiSearch />
              </IconButton>
            </InputAdornment>
          }
        />

        <Button className="outlined_btn ml-15" variant="outlined">
          {" "}
          Export{" "}
        </Button>
      </div>
      <Card className="responsivecard">
        <CardContent>

   {/*********************  Reporting  Table  ******************** */}


          <Div>
            <Table className="common_table" id="tablePopover">
              <TableHead>
                <TableRow>
                  <TableCell>
                    {" "}
                    <TableSortLabel>User Id</TableSortLabel>{" "}
                  </TableCell>

                  <TableCell>
                    <TableSortLabel>Reporting Date</TableSortLabel>{" "}
                  </TableCell>

                  <TableCell>
                    {" "}
                    <TableSortLabel> Reporter </TableSortLabel>
                  </TableCell>

                  <TableCell>
                    <TableSortLabel> Reporter On</TableSortLabel>{" "}
                  </TableCell>

                  <TableCell>
                    {" "}
                    <TableSortLabel>Reporter Feedback</TableSortLabel>{" "}
                  </TableCell>

                  <TableCell>
                    {" "}
                    <TableSortLabel>Reporter Gender</TableSortLabel>
                  </TableCell>

                  <TableCell>Status</TableCell>

                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  // Display the skeleton loader while data is loading
                  <SkeletonTableLoader tablecell="9" row="8" />
                ) : filteredData.length === 0 ? (
                  <NoRecordsFound tablecell="7" />
                ) : (
                  filteredData.map((item, i) => {
                    return (
                      <TableRow key={i}>
                        <TableCell>{item?.userid}</TableCell>
                        <TableCell>{item?.created_at}</TableCell>
                        <TableCell className="">
                          <div
                            className="profile_parent"
                          >
                            <img
                              src={`${item?.img}`}
                              alt="img"
                              //src={`${img_path}${item?.img}`}
                              className="profile_bg"
                            />
                            {item?.premium === 1 ? (
                              <PremiumIcon className="premium_bg" />
                            ) : (
                              ""
                            )}
                            <span className="ml-5">{item?.name}</span>
                          </div>
                        </TableCell>

                        <TableCell className="">
                          <div
                          className="profile_parent"
                            
                          >
                            <img
                              src={`${item?.reporter_img}`}
                              alt="img"
                              //src={`${img_path}${item?.img}`}
                              className="profile_bg"
                            />
                            {item?.reporter_premium === 1 ? (
                              <PremiumIcon className="premium_bg" />
                            ) : (
                              ""
                            )}
                            <span className="ml-5">{item?.reporter_name}</span>
                          </div>
                        </TableCell>
                        <TableCell>{item?.feedback}</TableCell>
                        <TableCell>{item?.gender}</TableCell>

                        <TableCell>
                          {item?.status === 1 ? (
                            <span className="status-active">Active</span>
                          ) : item?.status === 0 ? (
                            <span className="status-inactive">Inactive</span>
                          ) : item?.status === 2 ? (
                            <span className="status-blocked">Blocked</span>
                          ) : (
                            ""
                          )}
                        </TableCell>

                        <TableCell>
                          <Tooltip title=" View ">
                            {" "}
                            <IconButton
                              className="ml-10 svgtable_icon_btn"
                              variant="outlined"
                            >
                              {" "}
                              <EyeIcon />{" "}
                            </IconButton>
                          </Tooltip>

                          <Tooltip title="Actions " className="ml-10">
                            <IconButton
                              className="table_icon_btn"
                              id="demo-positioned-button"
                              aria-controls={
                                open ? "demo-positioned-menu" : undefined
                              }
                              aria-haspopup="true"
                              aria-expanded={open ? "true" : undefined}
                              onClick={handleClick}
                            >
                              <MoreVertIcon />
                            </IconButton>
                          </Tooltip>

                          <Menu
                            className="custom_popover"
                            id="demo-positioned-menu "
                            aria-labelledby="demo-positioned-button"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                          >
                            <MenuItem
                              className="menuItem"
                              onClick={handleClose}
                            >
                              View
                            </MenuItem>
                            <MenuItem
                              className="menuItem"
                              onClick={handleDelete}
                            >
                              Delete
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}

                {/* 
{loading ? (
        // Display the skeleton loader while data is loading
        <SkeletonBeneficiariesList tablecell="7" row="8"/>
      ) : (
       

      )} */}
              </TableBody>

           {/********************* Table footer and pagination ******************** */}
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={100} >
                    <div className="table_pagination">
                      <div className="items_per_page">
                        <Select
                          className="pagination_select"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={page}
                          onChange={handleChangePage}
                        >
                          <MenuItem value={10}>10</MenuItem>
                          <MenuItem value={20}>20</MenuItem>
                          <MenuItem value={30}>30</MenuItem>
                        </Select>
                        <p>Items per Page</p>
                      </div>

                      <Stack spacing={2}>
                        <Pagination count={10} />
                      </Stack>
                    </div>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </Div>
        </CardContent>
      </Card>
      {/* <ToastContainer /> */}
      <SubscrptionsCreate
        handleDrawerClose={handleDrawerClose}
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
      />
      {loader === true ? <BackdropLoader /> : ""}
    </section>
  );
};

/*******************  function used for tabs */
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default ReportingUser;
