import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Card } from "@mui/material";

const Home = () => {
  useEffect(() => {});
  return (
    <div>
      <Box>
        <Grid container spacing={2}>
          <Grid item lg={7} xs={12} spacing={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={5} spacing={2}>
                <Card sx={{ height: "220px" }}></Card>
              </Grid>
              <Grid item xs={12} lg={7} spacing={2}>
                <Card
                  className="qucik_access_card"
                  sx={{ height: "220px" }}
                ></Card>
              </Grid>
            </Grid>
            <Grid container xs={12}>
              <Grid item xs={12}>
                <Card sx={{ height: "670px", mt: 2 }}></Card>
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={5} xs={12}>
            <Card
              className="recent_transaction_card"
              sx={{ height: "895px" }}
            ></Card>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Home;
