import React, { Fragment, useState } from 'react';
import { FiArrowRight } from "react-icons/fi";
import Div from "@jumbo/shared/Div";
import { CardContent, Card, Typography, Button, } from "@mui/material";
import * as yup from 'yup';
import { Form, Formik } from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import BlockIcon from '@mui/icons-material/Block';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { config } from 'app/config/main';
import {  toast } from 'react-toastify';
import { useEffect } from 'react';
import ListIcon from '@mui/icons-material/List';

const validationSchema = yup.object({
    roleName: yup
        .string('Enter Role')
        .required('Role is required'),
});


const UpdateRole = () => {
    const navigate = useNavigate();
    const token = useSelector(state => state.auth?.accessToken);
    const id = window.location.pathname.split('/')[4]
    const [data, setData] = useState();

    const loadData = async () => {
        try {
            const options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }
            await fetch(`${config.apiUrl}/role/view/${id}`, options)
                .then(res => res.json())
                .then(res => {
                    console.log(res)
                    setData(res.data[0])
                })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(()=>{
        loadData()
    }, [])
    
    const handleSubmit = async (data) => {
        console.log(data)
        try {
            const bodyData = {
                id: id,
                name: data.roleName
            }
            const options = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(bodyData)
            }
            await fetch(`${config.apiUrl}/role/update`, options)
                .then(res => res.json())
                .then(res => {
                    console.log(res)
                    if (res.status === true) {
                        toast.success('Role Updated Successfully')
                        setTimeout(()=>{
                            navigate('/settings/manage-roles')
                        }, 1000)
                    } else {
                        toast.error(res.message)
                    }
                })
        } catch (error) {
            console.log(error)
        }
    }

    const handleCanel = () => {
        navigate('/settings/manage-roles')
    }
    const handlePrevious = () => {
        navigate('/settings/manage-roles')
    }
    return (
        <Fragment>
              <Div className="d-flex items-center space-between mb-30">
        <Typography className="page_header">{"Update User"}</Typography>
        <Button
          startIcon={<ListIcon />}
          variant="outlined"
          className="link_btn"
          onClick={handlePrevious}
        >
          List
        </Button>
      </Div>
            <Div className='update-role'>
                <Card sx={{ width: 650 }}>
                    <CardContent>
                        <Formik
                            enableReinitialize={true}
                            validateOnChange={true}
                            initialValues={{
                                roleName: data?.name
                            }}
                            validationSchema={validationSchema}
                            onSubmit={(data, { setSubmitting, resetForm }) => {
                                setSubmitting(true);
                                handleSubmit(data);
                                setSubmitting(false);
                                
                            }}
                        >
                            <Form>
                                <Div sx={{ mb: 3, mt: 1, width: 450 }}>
                                <Typography className="from_label">
                    ENTER ROLE NAME
                  </Typography>
                                    <JumboTextField
                                        fullWidth
                                        name="roleName"
                                        
                                        value={data?.name}
                                        defaultValue={'Role Name'}
                                    />
                                </Div>
                                <Div sx={{ display: 'flex', marginTop: '1.5rem', marginLeft: 'auto', justifyContent: 'right' }}>
                                <Button
                    endIcon={<BlockIcon />}
                    variant="outlined"
                    className="activity_cancel"
                    onClick={handleCanel}
                  >
                    Cancel
                  </Button>

                  <Button
                   endIcon={<FiArrowRight />}
                   type="submit"
                   className="activity_submit"
                   
                  >
                    Submit
                    
                  </Button>
                                </Div>
                            </Form>
                        </Formik>
                    </CardContent>
                </Card>
            </Div>
        </Fragment>
    )
}

export default UpdateRole;