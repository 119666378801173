import Div from "@jumbo/shared/Div";
import {
  Card,
  CardContent,
  Grid,
  Pagination,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { TableSortLabel } from "@mui/material";
import DeletePopup from "../component/deleteModal";

import { ToastServices } from "app/services/toastServices";
import BackdropLoader from "../component/loader";
import SkeletonTableLoader from "../component/skeleton_loader/skeletonTableLoader";

import NoRecordsFound from "../component/noRecordsFound";
import SubscrptionsCreate from "../Subscriptions/SubscrptionCreate/SubscrptionsCreate";
import FilterListIcon from "@mui/icons-material/FilterList";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
import ClearAllOutlinedIcon from "@mui/icons-material/ClearAllOutlined";
import IosShareOutlinedIcon from "@mui/icons-material/IosShareOutlined";
import Tooltip from "@mui/material/Tooltip";
import { CiSearch } from "react-icons/ci";
import { InputAdornment, OutlinedInput } from "@mui/material";
import {
  FilterIcon,
  RefreshIcon,
  EyeIcon,
  ActionIcon,
  PremiumIcon,
} from "app/assets/svg/Svg";

import VisibilityIcon from "@mui/icons-material/Visibility";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DyanamicDatePicker from "../component/DatePicker/DyanamicDatePicker";
import img1 from "../../../assets/user_img/img1.png";

const ThemeHelper = () => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [value, setValue] = React.useState(0);

  const tabhandleChange = (event, newValue) => {
    setValue(newValue);
  };

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }
  function handleDrawerClose() {
    setMobileOpen(false);
  }
  const [age, setAge] = React.useState("20");

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const TableData = [
    {
      name: "Gold",
      bank: "1200.00",
      acno: "6 months",
      ifsc: "Included",
      mobile: "Auto",
      actype: 1,
    },
    {
      name: "Free Trail",
      bank: "0.00",
      acno: "15 Days",
      ifsc: "Not Included",
      mobile: "Manual",
      actype: 2,
    },
    {
      name: "Platinum",
      bank: "1800.00",
      acno: "2 months",
      ifsc: "Included",
      mobile: "Auto",
      actype: 0,
    },
    {
      name: "Gold",
      bank: "1200.00",
      acno: "6 months",
      ifsc: "Included",
      mobile: "Auto",
      actype: 1,
    },
    {
      name: "Platinum",
      bank: "1800.00",
      acno: "2 months",
      ifsc: "Included",
      mobile: "Auto",
      actype: 0,
    },
  ];

  const terms = ["name", "bank", "acno", "ifsc", "mobile", "actype"];

  const [filteredData, setFilteredData] = useState(TableData);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);

  /* useEffect(() =>{
    setLoader(true)
    setTimeout(()=>{
      setLoader(false)
 },3000) }, []) */

  const handleSearch = (filteredData, loading) => {
    // Do something with the filtered data
    setFilteredData(filteredData);
    setLoading(loading);
  };

  const [isModalOpen, setModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setLoader(false);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDelete = () => {
    setAnchorEl(null);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const shoToast = () => {
    ToastServices.ErroToast("Test toaster");
    ToastServices.SucessToast("Test toaster");
    ToastServices.WarnToast("Test toaster");
    ToastServices.InfoToast("Test toaster");
  };
  const [page, setPage] = React.useState(10);

  const handleChangePage = (event) => {
    setPage(event.target.value);
  };

  return (
    <section className="current_beneficiaty">
      <DeletePopup open={isModalOpen} handleClose={handleCloseModal} />

      <Card className="responsivecard">
        <CardContent>
          <Typography variant={"h2"}>{`Icon Button`}</Typography>
          <div className="d-flex items-center flex-wrap mb-10">
            <Tooltip title="Filter">
              {" "}
              <IconButton className="ml-10 icon_btn" variant="">
                {" "}
                <FilterListIcon />{" "}
              </IconButton>
            </Tooltip>
            <Tooltip title="Refresh">
              <IconButton className="ml-10 icon_btn" variant="outlined">
                {" "}
                <CachedOutlinedIcon />{" "}
              </IconButton>
            </Tooltip>
            <Tooltip title="Filter ">
              {" "}
              <IconButton className="ml-10 icon_btn" variant="outlined">
                {" "}
                <ClearAllOutlinedIcon />{" "}
              </IconButton>
            </Tooltip>
            <Tooltip title="View ">
              {" "}
              <IconButton className="ml-10 table_icon_btn" variant="outlined">
                {" "}
                <VisibilityIcon />{" "}
              </IconButton>
            </Tooltip>
            <Tooltip title="Actions">
              {" "}
              <IconButton className="ml-10 table_icon_btn" variant="outlined">
                {" "}
                <MoreVertOutlinedIcon />{" "}
              </IconButton>
            </Tooltip>
            <Tooltip title="SVG icon View ">
              {" "}
              <IconButton
                className="ml-10 svgtable_icon_btn"
                variant="outlined"
              >
                {" "}
                <EyeIcon />{" "}
              </IconButton>
            </Tooltip>
            <Tooltip title="SVG icon Action ">
              {" "}
              <IconButton
                className="ml-10  svgtable_icon_btn  action_icon_padding"
                variant="outlined"
              >
                {" "}
                <ActionIcon />{" "}
              </IconButton>
            </Tooltip>
            <Tooltip title="SVG icon Refresh">
              {" "}
              <IconButton className="ml-10 icon_btn" variant="outlined">
                {" "}
                <RefreshIcon />{" "}
              </IconButton>
            </Tooltip>
            <Tooltip title="SVG icon Filter">
              {" "}
              <IconButton className="ml-10 icon_btn" variant="outlined">
                {" "}
                <FilterIcon />{" "}
              </IconButton>
            </Tooltip>
            <img src={img1} alt="img" className="profile_bg ml-10"></img>
            <PremiumIcon className="premium_bg" />
          </div>
          <div style={{ marginBottom: "40px" }}>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Typography
                  className=""
                  variant={"h2"}
                >{` Datepicker`}</Typography>
                <DyanamicDatePicker />
              </Grid>
            </Grid>
          </div>

          <Typography
            className=""
            variant={"h2"}
          >{`Outlined Button`}</Typography>
          <div className="d-flex items-center flex-wrap  mb-10">
            <Button
              startIcon={<FileDownloadIcon fontSize="large" />}
              className="outlined_btn ml-10 "
              variant="outlined"
            >
              {" "}
              Export{" "}
            </Button>
            <Button className="outlined_btn ml-10" variant="outlined">
              {" "}
              Export{" "}
            </Button>

            {/*  <Button startIcon={<GrDocumentExcel  />} className='export_btn ml-10 ' variant='outlined'  > Export </Button>
               <Button className='export_btn ml-10' variant='outlined'  > Export </Button> */}
          </div>

          <Typography className=" " variant={"h2"}>{` Button`}</Typography>
          <div className="d-flex items-center flex-wrap mt-10 mb-10">
            <Button
              startIcon={<IosShareOutlinedIcon />}
              size="large"
              className="ml-10 filled_btn"
              variant="contained"
            >
              {" "}
              Save{" "}
            </Button>
            <Button
              size="large"
              className="ml-10 filled_btn"
              variant="contained"
            >
              {" "}
              Login{" "}
            </Button>
          </div>
          <Typography className="" variant={"h2"}>{` Status `}</Typography>
          <div className="d-flex items-center flex-wrap mb-10">
            <span className="status-active ml-10"> Active</span>
            <span className="status-inactive ml-10"> Inactive</span>
            <span className="status-blocked ml-10"> Blocked</span>
            <span className="status-success ml-10"> Success</span>
            <span className="status-failed ml-10"> Failed</span>
            <span className="status-pending ml-10"> Pending</span>
            <span className="status-expired ml-10"> Expired</span>
            <span className="status-chatting ml-10"> Chatting</span>
            <span className="status-notyetstarted ml-10"> Not yet Started</span>
          </div>

          <Typography className="" variant={"h2"}>{` Date Picker`}</Typography>
          <div className="d-flex items-center flex-wrap mt-10 mb-10">
            <div className="ml-20 date_picker">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker />
              </LocalizationProvider>
            </div>
            <span className="ml-20 "> - </span>
            <div className="ml-20 date_picker">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker />
              </LocalizationProvider>
            </div>

            {/**  <div className="date_picker ml-10">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer className=" ml-10"  components={['SingleInputDateRangeField']}>
    
        <DateRangePicker
          slots={{ field: SingleInputDateRangeField }}
          name="allowedRange"
        />
        
      </DemoContainer>
    </LocalizationProvider>
    </div>

*/}
          </div>

          <Typography className="" variant={"h2"}>{` Search bar`}</Typography>
          <div className="d-flex items-center mt-10">
            <Div className="ml-10">
              <OutlinedInput
                className="search_bar ml-10"
                id="outlined-adornment-password"
                placeholder="Search by Keyword"
                startAdornment={
                  <InputAdornment position="start">
                    <IconButton edge="start">
                      <CiSearch />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Div>

            <Div className="ml-10">
              <OutlinedInput
                className="search w-100 ml-10"
                id="outlined-adornment-password"
                placeholder="Search"
                startAdornment={
                  <InputAdornment position="start">
                    <IconButton edge="start">
                      <CiSearch />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Div>
          </div>

          <Typography className="mt-20" variant={"h2"}>{`Tabs`}</Typography>

          <div className="d-flex items-center flex-wrap mt-10">
            <Box sx={{ width: "100%" }}>
              <Box>
                <Tabs
                  indicatorColor="secondary"
                  className="custom-tabs"
                  value={value}
                  onChange={tabhandleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="All (13)" {...a11yProps(0)} />
                  <Tab label="Active(8)" {...a11yProps(1)} />
                  <Tab label="Inactive(3)" {...a11yProps(2)} />
                  <Tab label="Blocked(2)" {...a11yProps(3)} />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                Item One value
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                Item Two value
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                Item Three Value
              </CustomTabPanel>
              <CustomTabPanel value={value} index={3}>
                Item Four Value
              </CustomTabPanel>
            </Box>

            {/**another tabs */}
            <Box sx={{ width: "100%" }}>
              <Box>
                <Tabs
                  className="custom-tabs-2"
                  value={value}
                  onChange={tabhandleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="All Users" {...a11yProps(0)} />
                  <Tab label="Normal Users" {...a11yProps(1)} />
                  <Tab label="Prime Users" {...a11yProps(2)} />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                Item One value
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                Item Two value
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                Item Three Value
              </CustomTabPanel>
            </Box>
            {/**another tabs */}
          </div>

          <div
            className="page_header_option beneficiary space-between mt-30"
            style={{ alignItems: "baseline" }}
          >
            <Typography className="mt-20" variant={"h2"}>{`Table`}</Typography>
          </div>
          <Div>
            <Table className="common_table" id="tablePopover">
              <TableHead>
                <TableRow>
                  <TableCell>
                    {" "}
                    <TableSortLabel>Plan Name</TableSortLabel>{" "}
                  </TableCell>
                  <TableCell style={{ textAlign: "end", paddingRight: "30px" }}>
                    <TableSortLabel>Price (₹)</TableSortLabel>{" "}
                  </TableCell>
                  <TableCell>
                    {" "}
                    <TableSortLabel> Validity </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel>Special Features</TableSortLabel>{" "}
                  </TableCell>
                  <TableCell>
                    {" "}
                    <TableSortLabel>Renewal Type</TableSortLabel>{" "}
                  </TableCell>
                  <TableCell>
                    {" "}
                    <TableSortLabel>Status</TableSortLabel>
                  </TableCell>
                  <TableCell sx={{ width: "20px" }}></TableCell>
                  <TableCell sx={{ width: "20px" }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  // Display the skeleton loader while data is loading
                  <SkeletonTableLoader tablecell="7" row="8" />
                ) : filteredData.length === 0 ? (
                  <NoRecordsFound tablecell="7" />
                ) : (
                  filteredData.map((item, i) => {
                    return (
                      <TableRow key={i}>
                        <TableCell>{item?.name}</TableCell>
                        <TableCell
                          style={{ textAlign: "end", paddingRight: "30px" }}
                        >
                          {item?.bank}
                        </TableCell>
                        <TableCell>{item?.acno}</TableCell>
                        <TableCell>{item?.ifsc}</TableCell>
                        <TableCell>{item?.mobile}</TableCell>
                        <TableCell>
                          {item?.actype === 1 ? (
                            <span className="status-active ml-10">Active</span>
                          ) : item?.actype === 0 ? (
                            <span className="status-inactive ml-10">
                              Inactive
                            </span>
                          ) : item?.actype === 2 ? (
                            <span className="status-blocked ml-10">
                              Blocked
                            </span>
                          ) : (
                            ""
                          )}
                        </TableCell>
                        <TableCell>
                          {" "}
                          <Tooltip title="View ">
                            {" "}
                            <IconButton
                              className="ml-10 table_icon_btn"
                              variant="outlined"
                            >
                              {" "}
                              <VisibilityIcon />{" "}
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        <TableCell sx={{ width: "20px" }}>
                          <Tooltip title="Actions ">
                            <IconButton
                              className="table_icon_btn"
                              id="demo-positioned-button"
                              aria-controls={
                                open ? "demo-positioned-menu" : undefined
                              }
                              aria-haspopup="true"
                              aria-expanded={open ? "true" : undefined}
                              onClick={handleClick}
                            >
                              <MoreVertIcon />
                            </IconButton>
                          </Tooltip>
                          <Menu
                            className="custom_popover"
                            id="demo-positioned-menu "
                            aria-labelledby="demo-positioned-button"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                          >
                            <MenuItem
                              className="menuItem"
                              onClick={handleClose}
                            >
                              View
                            </MenuItem>
                            <MenuItem
                              className="menuItem"
                              onClick={handleDelete}
                            >
                              Delete
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}

                {/* 
{loading ? (
        // Display the skeleton loader while data is loading
        <SkeletonBeneficiariesList tablecell="7" row="8"/>
      ) : (
       

      )} */}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={100} style={{ padding: "0" }}>
                    <div className="table_pagination">
                      <div className="items_per_page">
                        <Select
                          className="pagination_select"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={page}
                          onChange={handleChangePage}
                        >
                          <MenuItem value={10}>10</MenuItem>
                          <MenuItem value={20}>20</MenuItem>
                          <MenuItem value={30}>30</MenuItem>
                        </Select>
                        <p>Items per Page</p>
                      </div>

                      <Stack spacing={2}>
                        <Pagination count={10} />
                      </Stack>
                    </div>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </Div>
        </CardContent>
      </Card>
      {/* <ToastContainer /> */}
      <SubscrptionsCreate
        handleDrawerClose={handleDrawerClose}
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
      />
      {loader === true ? <BackdropLoader /> : ""}
    </section>
  );
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default ThemeHelper;
