import React, { Fragment, useEffect, useState } from "react";



import Div from "@jumbo/shared/Div";
import {
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Card,
  Typography,
  Chip,
  TableFooter,
  OutlinedInput,
  InputAdornment,
  Stack,
  Pagination,
  TableSortLabel,
} from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";
import SearchGlobal from "app/shared/SearchGlobal/SearchGlobal";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";
import { config } from "app/config/main";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { hasActionPermissionList } from "app/utils/menuHelpers";
import debounce from "lodash/debounce";

import { CiSearch } from "react-icons/ci";
import { IoMdEye } from "react-icons/io";
import { RiDeleteBin6Line } from "react-icons/ri";
import SkeletonUsersList from "app/pages/skeleton/SkeletonUsersList";
import NoRecordsFound from "app/pages/app-pages/component/noRecordsFound";
import SkeletonTableLoader from "../component/skeleton_loader/skeletonTableLoader";
import Search from "../component/search";
import { ToastServices } from "app/services/toastServices";
import BackdropLoader from "../component/loader";

const ManageUsers = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  
  const [current, setCurrent] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const portal = useSelector((state) => state?.user?.userData?.portal);
const [createAccess, setCreate] = useState(false);
  const [updateAccess, setUpdate] = useState(false);
  const [deleteAccess, setDelete] = useState(false);
  const [statusAccess, setStatus] = useState(false);
  const [loader, setLoader] = useState(false)

//   Pagination 
const [currentPage, setCurrentPage] = useState(1);
const [itemsPerPage, setItemsPerPage] = useState(10);
const [totalItems, setTotalItems] = useState(0);
const [count, setCount] = useState(0)
const [searchQuery, setSearchQuery] = useState('');
const [sortBy, setSortBy] = useState('');
const [sortOrder, setSortOrder] = useState('asc');
const handleItemsPerPageChange = (event) => {
    setItemsPerPage(event.target.value);
    setCurrentPage(1);
  };

  const handlePaginationChange = (event, page) => {
    setCurrentPage(page);
  };

  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearchQuery(value);

    setLoader(true)
    if (debounceTimeout) {
        setLoader(false)
      clearTimeout(debounceTimeout);
    }

    
    const newTimeout = setTimeout(() => {
      loadUserData(currentPage, itemsPerPage, value);
    }, 900); 

    setDebounceTimeout(newTimeout);
  };




  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const token = useSelector((state) => state.auth?.accessToken);

  const handleClick = (event, item) => {
    setCurrent(item);
    setAnchorEl(event?.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const loadUserData = async (page, limit, search, order , by) => {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    setLoader(true)
    await fetch(`${config.apiUrl}/users?sortByType=${order}&sortByKey=${by}&searchWith=${search}&limit=${limit}&offset=${page}`, options)
      .then((res) => res.json())
      .then((res) => {
        setLoader(false)
        console.log(res.data);
        setData(res.data);
        setCount(res.count);
        setTotalItems(res.totalPages);

        

      });
  };

  const handleCreate = () => {
    navigate("/settings/manage-users/create");
  };

  const handleUpdate = () => {
    setAnchorEl(null);
    navigate(`/settings/manage-users/update/${current.id}`);
  };

  const handleStatus = async () => {
    try {
      const bodyData = {
        id: current.id,
        status: current.status === 1 ? 0 : 1,
      };
      const options = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(bodyData),
      };
      setLoader(true)
      await fetch(`${config.apiUrl}/users/status`, options)
        .then((res) => res.json())
        .then(
          (res) => {
            setLoader(false)
            console.log(res);
            if (res.status === true) {
                ToastServices.SucessToast("Users status Updated Successfully")
            
              loadUserData(currentPage, itemsPerPage, searchQuery);
            } else {
                ToastServices.ErroToast(res.message);
            }
            setAnchorEl(null);
          },
          (err) => {
            console.log(err);
          }
        );
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async () => {
    try {
      const options = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      setLoader(true)
      await fetch(`${config.apiUrl}/users/delete/${current.id}`, options)
        .then((res) => res.json())
        .then(
          (res) => {
            setLoader(false)
            console.log(res);
            if (res.status === true) {
             ToastServices.SucessToast("Users Deleted Successfully");
              loadUserData(currentPage, itemsPerPage, searchQuery);
            } else {
              ToastServices.ErroToast(res.message);
            }
            setAnchorEl(null);
            setModalOpen(true);
          },
          (err) => {
            console.log(err);
          }
        );
    } catch (error) {
      console.log(error);
    }
  };
useEffect(() => {
    loadUserData(currentPage, itemsPerPage, searchQuery)

 
}, [currentPage, itemsPerPage, searchQuery])

  useEffect(() => {
    loadUserData(currentPage, itemsPerPage, searchQuery, sortOrder , sortBy)
    if (portal === "admin") {
      setCreate(true);
      setUpdate(true);
      setDelete(true);
      setStatus(true);
    } else {
      hasActionPermissionList("user_create").then((res) => {
        setCreate(res);
      });
      hasActionPermissionList("user_update").then((res) => {
        setUpdate(res);
      });
      hasActionPermissionList("user_delete").then((res) => {
        setDelete(res);
      });
      hasActionPermissionList("user_status").then((res) => {
        setStatus(res);
      });
    }
  }, []);

  const [isModalOpen, setModalOpen] = useState(false);
  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const open = Boolean(anchorEl);

  const TableData = [
    {
      sno: "01",
      name: "Arjun ",
      mobile: "712345689",
      email: "xyz@gmail.com",
      role: "Project Manger",
      status: 1,
    },
    {
      sno: "02",
      name: "Ananya",
      mobile: "712345689",
      email: "xyz@gmail.com",
      role: "Project Co-ordinator",
      status: 0,
    },
    {
      sno: "03",
      name: "Kavin",
      mobile: "712345689",
      email: "xyz@gmail.com",
      role: "Project Leader",
      status: 1,
    },
    {
      sno: "04",
      name: "Nithya",
      mobile: "712345689",
      email: "xyz@gmail.com",
      role: "Software Developer",
      status: 0,
    },
    {
      sno: "05",
      name: "Vikram",
      mobile: "712345689",
      email: "xyz@gmail.com",
      role: "UI Designer",
      status: 1,
    },
    {
      sno: "06",
      name: "Priya",
      mobile: "712345689",
      email: "xyz@gmail.com",
      role: "UX Designer",
      status: 0,
    },
    {
      sno: "07",
      name: "Siddharth",
      mobile: "712345689",
      email: "xyz@gmail.com",
      role: "Automation Tester",
      status: 1,
    },
    {
      sno: "08",
      name: "Nandini",
      mobile: "712345689",
      email: "xyz@gmail.com",
      role: "Manual Tester",
      status: 0,
    },
  ];

  const terms = ["name", "mobile", "email", "role"];

  const [filteredData, setFilteredData] = useState(TableData);
  const [loading, setLoading] = useState(false);

  const handleSearch = (filteredData, loading) => {
    // Do something with the filtered data
    setLoading(loading);
    setFilteredData(filteredData);
  };
 
  const handleSort = (column) => {
    if (sortBy === column) {
      
      setSortOrder(sortOrder === 'ASC' ? 'DESC' : 'ASC');
      loadUserData(currentPage, itemsPerPage, searchQuery, sortOrder , sortBy)
    } else {
    
      setSortBy(column);
      setSortOrder('asc');
    }
  };

  return (
    <Fragment>
      <Card>
        <CardContent>
          <div
            className="page_header_option space-between mb-30"
            style={{ alignItems: "baseline" }}
          >
            <div className="d-flex items-center space-between w-100">
              <div className="d-flex items-center ">
                <Typography
                  className="page_header"
                  variant={"h2"}
                >{`${count == undefined ? "0" :count } Manage Users `}</Typography>
                <Typography
                  className="create_btn"
                  variant={"h2"}
                  onClick={handleCreate}
                >
                  + Add new
                </Typography>
              </div>

              <div className="ml-20">
              <OutlinedInput
          className="form_new_style w-100"
          id="outlined-adornment-password"
          placeholder="Search"
          value={searchQuery}
           onChange={handleSearchChange}
          startAdornment={
            <InputAdornment position="start">
              <IconButton edge="start" onClick={handleSearchChange} >
                <CiSearch/>
              </IconButton>
            </InputAdornment>
          }
        />
              </div>
            </div>
          </div>

          <Div>
            <Table className="common_table" id="tablePopover">
              <TableHead>
                <TableRow>
                    <TableCell>
                    <TableSortLabel
                  active={sortBy === 'name'}
                  direction={sortOrder}
                  onClick={() => handleSort('name')}
                  
                >
                    Name
                    </TableSortLabel>
                    </TableCell>
               
                  
                  <TableCell>Role Name</TableCell>
                  <TableCell>Mobile Number</TableCell>
                  <TableCell>Email Id</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              


                {  data.length == 0 ? <h2 className="text-center">No data found</h2> :
                    data.map(( item, i)=>{
                        return(
                            <TableRow key={i}>
                            <TableCell>{item?.name}</TableCell>
                            <TableCell>{item?.roleName}</TableCell>
                            <TableCell>{item?.mobileno}</TableCell>
                            <TableCell>{item?.email}</TableCell>
                            <TableCell>
                              {item?.status == 1 ? (
                                <span className="admin_active_status">
                                  {" "}
                                  <span className="active_dot"></span> Active
                                </span>
                              ) : (
                                <span className="admin_inactive_status">
                                  <span className="inactive_dot"></span> Inactive
                                </span>
                              )}
                            </TableCell>
                            {(updateAccess === true ||
                              deleteAccess === true ||
                              statusAccess === true) && (
                              <TableCell>
                                <IconButton
                                  aria-label="more"
                                  id="long-button"
                                  aria-controls={open ? "long-menu" : undefined}
                                  aria-expanded={open ? "true" : undefined}
                                  aria-haspopup="true"
                                  onClick={(e) => handleClick(e, item)}
                                >
                                  <MoreVertIcon />
                                </IconButton>
                                <Menu
                                  id="fade-menu"
                                  className="custom_popover"
                                  MenuListProps={{
                                    "aria-labelledby": "fade-button",
                                  }}
                                  anchorEl={anchorEl}
                                  open={open}
                                  onClose={handleClose}
                                  TransitionComponent={Fade}
                                >
                                  {updateAccess === true && (
                                    <MenuItem onClick={() => handleUpdate()}>
                                      Update
                                    </MenuItem>
                                  )}
                                  {statusAccess === true && (
                                    <MenuItem onClick={() => handleStatus()}>
                                      {current?.status === 1
                                        ? "Mark as Inactive"
                                        : "Mark as Active"}
                                    </MenuItem>
                                  )}
                                  {deleteAccess === true && (
                                    <MenuItem onClick={() => handleDelete()}>
                                      Delete
                                    </MenuItem>
                                  )}
                                </Menu>
                              </TableCell>
                            )}
                          </TableRow>

                        )
                    })
                }

             
              </TableBody>

              {/* <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  
                  count={currentPage}
                  rowsPerPage={5}
                  page={1}
                /> */}
                
     
                
                

        


              
            </Table>
            <div className="pagination_containner ">
            <div className="count_page" >
              <span>Rows per page :</span>
            <select value={itemsPerPage} onChange={handleItemsPerPageChange}>
            <option value="1">1 </option>
            <option value="10">10</option>
            <option value="20">20 </option>
            <option value="30">30 </option>
          </select>
            </div>
         
          <Stack spacing={2}>
            <Pagination
              count={totalItems}
              page={currentPage}
              onChange={handlePaginationChange}
            />
          </Stack>
          </div>
          </Div>
        </CardContent>
      </Card>
      {
        loader == true ? <>  <BackdropLoader  /> </> : ""
      }
     
    </Fragment>
  );
};

export default ManageUsers;
