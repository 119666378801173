import React, { useContext, useState } from "react";
import {
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import Link from "@mui/material/Link";
import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import useApp from "app/hooks/useApp";
import { ToastServices } from "app/services/toastServices";
import { AuthenticationContext } from "app/utils/constants/AuthContext";
import LoadingButton from "@mui/lab/LoadingButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { SessionSvg } from "app/assets/svg/Svg";
import { MainLogo } from "app/assets/svg/logo";
const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup.string("Enter your password").required("Password is required"),
});

const Login1 = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const appData = useApp();
  const [showPassword, setShowPassword] = useState(false);
  const { isAuthenticated, login } = useContext(AuthenticationContext);


  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    touched,
    errors,
  } = useFormik({
    initialValues: {
      email: "admin@technogenesis.in",
      password: "12345678",
    },
    validationSchema: validationSchema,
    onSubmit: async (data) => {
      return new Promise((reslove, reject) => {
        const item = true;
        try {
          if (item != null) {
            
            sessionStorage.setItem("token", "1111");
            sessionStorage.setItem("refershtoken", "1111");
            sessionStorage.setItem("auth", true);
            sessionStorage.setItem("portal", "admin");
            login();
            navigate("/home", { replace: true });
            setLoading(false);
            reslove(item);
          } else {
            ToastServices.ErroToast("Test toaster");
            setLoading(false);
          }
        } catch (err) {
          reject(err);
        }
      });
    },
  });

  console.log(appData, "5465456");

  return (
    <div className="session_page ">
      <Grid className="h-full session_grid" container>
        <Grid
          className="session_image_content"
          item
          lg={6}
          style={{ alignSelf: "center" }}
        >
          <div className="session_content">
            <div className="session_subcont">
              <h1>Welcome Back!</h1>
              <p>
                Access the <span> administrative features</span> <br /> and
                manage your account with ease.
              </p>
            </div>
            <div className="session_img">
              <SessionSvg />
            </div>
          </div>
        </Grid>
        <Grid item lg={6} style={{ alignSelf: "center" }}>
          <div className="session_card flex_container_col">
            <div className="session_wrapper mb-10">
             <div className="text-center login_logo">
             <MainLogo  />
             </div>
               
              <div className="session_form">
                <div className="session_title">
                  <h2>Login</h2>
                  <p>Please login with your account</p>
                </div>
                <div style={{ marginTop: "60px" }}>
                  <form onSubmit={handleSubmit} noValidate autoComplete="off">
                    <div className="login_form_input responisve">
                      <Typography className="login_label">
                        Email Id <span className="required">*</span>
                      </Typography>
                      <TextField
                        fullWidth
                        name="email"
                        placeholder="Enter Email Id"
                        type="email"
                        variant="outlined"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.email && Boolean(errors.email)}
                      />
                      {touched.email ? (
                        <small className="error_text">{errors.email}</small>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="login_form_input responisve">
                      <Typography className="login_label">
                        Password <span className="required">*</span>
                      </Typography>
                      <OutlinedInput
                        className="password_input"
                        name="password"
                        placeholder="Enter Password"
                        fullWidth={true}
                        id="outlined-adornment-password"
                        type={showPassword ? "text" : "password"}
                        required={true}
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.password && Boolean(errors.password)}
                        helperText={touched.password ? errors.password : ""}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword((show) => !show)}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {touched.password ? (
                        <small className="error_text">{errors.password}</small>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="mt-40">
                      <LoadingButton
                        className="filled_btn"
                        fullWidth
                        type="submit"
                        variant="contained"
                        size="large"
                        loading={isSubmitting}
                      >
                        Login
                      </LoadingButton>
                    </div>

                    <Typography sx={{ mt: 2 }} textAlign={"center"}>
                      <Link
                        className="forget_link forgot responisve"
                        underline="none"
                        href="/forgot-password"
                      >
                        Forgot password?
                      </Link>
                    </Typography>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Login1;
