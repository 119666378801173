import React, { Fragment, useEffect } from 'react';
import { FiArrowRight } from "react-icons/fi";
import BlockIcon from '@mui/icons-material/Block'
import Div from "@jumbo/shared/Div";
import { CardContent,  Checkbox,  Card, Typography, Switch, Button } from "@mui/material";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined';
import Divider from "@mui/material/Divider";
import ListIcon from '@mui/icons-material/List';
import CachedIcon from '@mui/icons-material/Cached';
import EastIcon from '@mui/icons-material/East';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { config } from 'app/config/main';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { toast } from 'react-toastify';
import { ToastServices } from 'app/services/toastServices';

const PermissionList = () => {
    const navigate = useNavigate()
    const [data, setData] = useState([])
    const [listData, setList] = useState([])
    const [permission, setPermission] = useState([]);
    const token = useSelector(state => state.auth?.accessToken);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const id = window.location.pathname.split('/')[4]
    const [currentMenu, setCurrentMenu] = useState([]);

    const [checkedPermission, setChecked] = useState([]);

    const loadData = async () => {
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }

        await fetch(`${config.apiUrl}/permission/detail/${id}`, options)
            .then((res) => res.json())
            .then((res) => {
                console.log(res)
                if (res.status === true) {
                    const resData = res.data[0];
                    setData(resData.tabular_view);
                    setList(resData.list_view);
                    const rolePermission = resData?.role_permissions?.length > 0 && resData?.role_permissions?.map((i) => {
                        return i.acl_menu_id
                    });
                    const menuModuleId = resData?.role_permissions?.length > 0 && resData?.role_permissions?.map((i) => {
                        return {
                            acl_menu_id: i.acl_menu_id,
                            acl_menu_module_id: i.acl_menu_module_id
                        }
                    });
                    setChecked(menuModuleId);
                    setPermission(rolePermission);
                }
            }, (err) => {
                console.log(err)
            })
    }

    const formatData = (actions) => {
        const mainMenu = ["List", "Create", "Update", "Delete", "View"];
        let arrangedMenu = [], menu = [];

        const others = actions.filter((i) => mainMenu.includes(i.menu_name) === false)
        const listMenu = actions.filter((i) => mainMenu.includes(i.menu_name) === true)

        mainMenu.forEach((element) => {
            listMenu?.map(i => {
                if (i.menu_name === element) {
                    arrangedMenu.push(i);
                }
            })
        })
        for (var i = 0; i < 5; i++) {
            for (var j = 0; j < 5; j++) {
                if (arrangedMenu[j]?.menu_name === mainMenu[i]) {
                    menu.push(arrangedMenu[j])
                    break;
                }
            }

            if (arrangedMenu[j]?.menu_name !== mainMenu[i]) {
                menu.push(0)
            }

        }
        return {
            others: others,
            menu: menu
        }
    }

    const handlePermission = (menuId, moduleId) => {
        if (permission.includes(menuId) === false) {
            let roleset = {
                acl_menu_id: menuId,
                acl_menu_module_id: moduleId
            }
            let menuData = new Array()
            menuData = checkedPermission?.length > 0 ? [...checkedPermission] : []
            menuData.push(roleset);
            let permissionList = permission?.length > 0 ? [...permission] : [];
            permissionList.push(menuId);
            setPermission(permissionList);
            setChecked(menuData);
        } else {
            const index = permission?.indexOf(menuId);
            if (index > -1) {
                const removePermission = permission?.length > 0 ? [...permission] : [];;
                removePermission?.splice(index, 1)
                setPermission(removePermission)
            }
            const checkedIndex = checkedPermission?.findIndex(i => i.acl_menu_id === menuId)
            if (checkedIndex > -1) {
                const removeChecked = checkedPermission?.length > 0 ? [...checkedPermission] : [];
                removeChecked?.splice(checkedIndex, 1);
                setChecked(removeChecked);
            }
        }

    }

    const handleClick = (event, menu) => {
        setCurrentMenu(menu)
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSubmission = async () => {
        const bodyData = {
            role_menu_id: [...checkedPermission],
            acl_role_id: id
        }
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(bodyData)
        }
        await fetch(`${config.apiUrl}/permission`, options)
            .then((res) => res.json())
            .then((res) => {
                console.log(res);
                if (res.status === true) {
                    ToastServices.SucessToast('Permissions Submitted Successfully!')
                    navigate('/settings/roles-permissions')
                } else {
                    // toast.error(res.message);
                    ToastServices.ErroToast(res.message)
                }
            }, (err) => {
                console.log(err)
            })
    }

    const handleClear = () => {
        setPermission([]);
        setChecked([])
    }

    useEffect(() => {
        loadData();
    }, []);

    const handlePrevious = () => {
        navigate('/settings/roles-permissions')
    }
    console.log(checkedPermission, permission)
    return (
        <Fragment>
                   <Div className="d-flex items-center space-between mb-30">
        <Typography className="page_header">{"Roles & Permissions"}</Typography>
        <Button
          startIcon={<ListIcon />}
          variant="outlined"
          className="link_btn"
          onClick={handlePrevious}
        >
          List
        </Button>
      </Div>

            <Card>
                <CardContent>
                    <Div>
                        {
                            listData?.length > 0 && listData.map((item) => {
                                return (
                                    <Div key={item}>

                                        <div className='d-flex'>
                                            <div className='w-15'>
                                                <h2 className='mw-25'>{item.name}</h2>
                                            </div>
                                            <div className='mt-1-5'>
                                                <KeyOutlinedIcon className='key-icon' />
                                            </div>
                                        </div>
                                        <div className='d-flex'>
                                            <div className='w-22'>
                                                <p className='mw-36'>{item.action[0]?.menu_name}</p>
                                            </div>
                                            <div className='mt-5'>
                                                <Switch
                                                    className='permission-switch'
                                                    checked={permission?.length > 0 && permission?.includes(item.action[0]?.acl_menu_id) === true ? true : false}
                                                    onChange={() => handlePermission(item.action[0]?.acl_menu_id, item.action[0].acl_menu_module_id)}
                                                />
                                            </div>
                                        </div>
                                    </Div>
                                )
                            })
                        }
                        <Divider />
                        <Div>
                            <h2>Modules Permissions</h2>
                        </Div>
                        <Divider />
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Modules</TableCell>
                                    <TableCell>List</TableCell>
                                    <TableCell>Create</TableCell>
                                    <TableCell>Update</TableCell>
                                    <TableCell>Delete</TableCell>
                                    <TableCell>View</TableCell>
                                    <TableCell>More</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    data?.map((item) => {
                                        const actionData = formatData(item.action)
                                        return (
                                            <TableRow key={item}>
                                                <TableCell>{item.name}</TableCell>
                                                {
                                                    actionData.menu?.length > 0 && actionData.menu?.map((i) => {
                                                        return (
                                                            <TableCell key={i}>{i != 0 ? <Checkbox value={i.acl_menu_id}
                                                                checked={permission?.length > 0 && permission?.includes(i.acl_menu_id) === true ? true : false}
                                                                onChange={() => handlePermission(i.acl_menu_id, item.id)}
                                                            /> : '-'}</TableCell>
                                                        )

                                                    })
                                                }
                                                <TableCell className='permission-list'>
                                                    <IconButton
                                                        style={{fontSize: "14px"}}
                                                        aria-label="more"
                                                        id="long-button"
                                                        aria-controls={open ? 'long-menu' : undefined}
                                                        aria-expanded={open ? 'true' : undefined}
                                                        aria-haspopup="true"
                                                        onClick={(e) => handleClick(e, actionData?.others)}
                                                    >
                                                        {actionData.others?.length > 0 ? `+${actionData.others?.length} More` : '-'}
                                                    </IconButton>
                                                    <Menu
                                                        id="fade-menu"
                                                        MenuListProps={{
                                                            'aria-labelledby': 'fade-button'
                                                        }}
                                                        anchorEl={anchorEl}
                                                        open={open}
                                                        onClose={handleClose}
                                                        TransitionComponent={Fade}
                                                    >
                                                        {
                                                            currentMenu?.length > 0 && currentMenu?.map((item) => {
                                                                return (
                                                                    <MenuItem key={item}><Checkbox value={item.acl_menu_id}
                                                                        checked={permission?.length > 0 && permission?.includes(item.acl_menu_id) === true ? true : false}
                                                                        onChange={() => handlePermission(item.acl_menu_id, item.acl_menu_module_id)}
                                                                    />{item.menu_name}</MenuItem>
                                                                )
                                                            })
                                                        }
                                                    </Menu>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </Div>
                </CardContent>
            </Card>
            <Div sx={{ display: 'flex', marginTop: '1.5rem', marginLeft: 'auto' }}>
               

                    <Button
                    endIcon={<BlockIcon />}
                    variant="outlined"
                    className="activity_cancel"
                    onClick={handleClear}
                  >
                    Clear
                  </Button>

                  <Button
                   endIcon={<FiArrowRight />}
                   type="submit"
                   className="activity_submit"
                   onClick={handleSubmission}
                  >
                    Submit
                    
                  </Button>





            </Div>
        </Fragment>
    )
}

export default PermissionList;