import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { Form, Formik } from "formik";
import {
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import Link from "@mui/material/Link";
import LoadingButton from "@mui/lab/LoadingButton";
import Div from "@jumbo/shared/Div";
import { SessionSvg } from "app/assets/svg/Svg";
import { MainLogo } from "app/assets/svg/logo";

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
});

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onSignIn = async (data) => {
    setLoading(true);
    navigate("/reset-password", { replace: true });
    setLoading(false);
  };

  return (
    <div className="session_page ">
      <Grid className="h-full session_grid" container>
        <Grid
          className="session_image_content"
          item
          lg={6}
          style={{ alignSelf: "center" }}
        >
          <div className="session_content">
            <div className="session_subcont">
              <h1>Welcome Back!</h1>
              <p>
                Access the <span> administrative features</span> <br /> and
                manage your account with ease.
              </p>
            </div>
            <div className="session_img">
              <SessionSvg />
            </div>
          </div>
        </Grid>
        <Grid item lg={6} style={{ alignSelf: "center" }}>
          <div className="session_card flex_container_col">
            <div className="session_wrapper mb-30">
            <div className="text-center login_logo">
             <MainLogo  />
             </div>
              <div className="session_form">
                <div className="session_title">
                  <h2>Forgot Password?</h2>
                  <p>No worries, we’ll send you reset instructions.</p>
                </div>
                <div style={{ marginTop: "60px" }}>
                  <Formik
                    validateOnChange={true}
                    initialValues={{
                      email: "admin@technogenesis.in",
                      password: "12345678",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(data, { setSubmitting }) => {
                      setSubmitting(true);
                      onSignIn(data);
                      setSubmitting(false);
                    }}
                  >
                    {({ isSubmitting }) => (
                      <Form
                        style={{ textAlign: "left" }}
                        noValidate
                        autoComplete="off"
                      >
                        <Div className="login_form_input responisve">
                          <Typography className="login_label">
                            Email Id
                          </Typography>
                          <TextField
                            fullWidth
                            name="email"
                            placeholder="Enter Email Id"
                            type="email"
                          />
                        </Div>

                        <Div className="mt-40">
                          <LoadingButton
                            className="filled_btn"
                            fullWidth
                            type="submit"
                            variant="contained"
                            size="large"
                            loading={loading}
                          >
                            Reset Password{" "}
                          </LoadingButton>
                        </Div>

                        <Typography sx={{ mt: 2 }} textAlign={"center"}>
                          <Link
                            className="forget_link"
                            underline="none"
                            href="/login"
                          >
                            Back to login
                          </Link>
                        </Typography>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ForgotPassword;
