import React, { useEffect, useState } from 'react';
import NotificationImportantOutlinedIcon from '@mui/icons-material/NotificationImportantOutlined';
import NotificationSharedPost from "./NotificationSharedPost";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import CardHeader from "@mui/material/CardHeader";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Button from "@mui/material/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Divider from "@mui/material/Divider";
import JumboIconButton from "@jumbo/components/JumboIconButton";
import JumboDdPopover from "@jumbo/components/JumboDdPopover";
import Div from "@jumbo/shared/Div";
import {Badge, CardActions, ThemeProvider} from "@mui/material";
import useJumboHeaderTheme from "@jumbo/hooks/useJumboHeaderTheme";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { setNotification } from 'app/redux/actions/notifyAction';
import Avatar from "@mui/material/Avatar";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Link from "@mui/material/Link";
import ListItemButton from "@mui/material/ListItemButton";
import StarIcon from '@mui/icons-material/Star';
import { IoNotificationsOutline } from "react-icons/io5";
import { GoBell } from "react-icons/go";
const NotificationsDropdown = () => {
    const {theme} = useJumboTheme();
    const {headerTheme} = useJumboHeaderTheme();
    const navigate = useNavigate();
    const token = useSelector(state => state.auth?.accessToken);
    const [unreadCount, setUnreadCount] = useState(0);
    const [notifyData, setNotifyData] = useState();

    const loadData = async() => {
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        await fetch(`https://apipayout.technogenesis.in/push/admin/notification-list`, options)
        .then(res => res.json())
        .then(res => {
            console.log(res)
            if(res.status === true) {
                setNotifyData(res.data);
                setNotification(res.data);
                const unreadData = res.data?.filter(i => i.msg_read === 0);
                setUnreadCount(unreadData?.length)
            }
        }, (err) => {console.log(err)})

    }

    const handleRead = async (id, msg_read) => {
        const bodyData = {
            id: id,
            msg_read: msg_read
        }
        const options = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(bodyData)
        }
        await fetch(`https://apipayout.technogenesis.in/push/read-unread`, options)
        .then(res => res.json())
        .then((res) => {
            console.log(res)
            loadData();
        }, (err)=>{
            console.log(err)
        })
    }

    useEffect(()=>{
        loadData()
    }, [])

    const handleView = () => {
        navigate('/notification-details')
    }

    return (
        <ThemeProvider theme={theme}>
            <JumboDdPopover
                triggerButton={
                    <ThemeProvider theme={headerTheme}>
                        <JumboIconButton  className="p-0"  badge={{variant: "standard", badgeContent: unreadCount }} >
                       
                            <GoBell className='notification_icon'/>
                            <span className='indicator'></span>
                        </JumboIconButton>
                    </ThemeProvider>
                }
                disableInsideClick
            >
                <Div sx={{width: 360, maxWidth: '100%'}}>
                    <CardHeader
                        title={"Notifications"}
                        action={<IconButton sx={{my: -1}}><MoreHorizIcon/></IconButton>}
                    />
                    <List disablePadding>
                        {
                            notifyData?.map((item, index) => {
                                return (
                                    index < 3 && <ListItemButton component={"li"} alignItems={"flex-start"} onClick={()=>handleRead(item.notification_id, item.msg_read)}>
                                    <ListItemAvatar className='notify'>
                                        <Avatar><StarIcon sx={{
                                            fill: '#64748B'
                                        }} /></Avatar>
                                    </ListItemAvatar>
                                    <ListItemText sx={{
                                        marginTop: '18px',
                                        display: 'flex'
                                    }}>
                                        <Link underline={"none"} href="#/">{item.module_name}</Link>
                                        <div className='d-flex'>
                                            <p className='notify-text'>{item.body}</p>
                                            {
                                                item.msg_read !== 1 ? <span className='dot'></span> : ''
                                            }
                                        </div>
                                    </ListItemText>
                                </ListItemButton>
                                )
                            })
                        }
                    </List>
                    <Divider/>
                    <CardActions sx={{justifyContent: 'center'}} onClick={handleView}>
                        <Button sx={{textTransform: "none", fontWeight: 'normal', '&:hover': {bgcolor: 'transparent'}}}
                                size={"small"} variant="text" endIcon={<ArrowForwardIcon/>} disableRipple >
                            View All
                        </Button>
                    </CardActions>
                </Div>
            </JumboDdPopover>
        </ThemeProvider>
    );
};

export default NotificationsDropdown;
