import React, { Fragment, useEffect, useState } from 'react';
import Div from "@jumbo/shared/Div";
import { CardContent, CardHeader, Checkbox, FormControlLabel, Card, Typography, Chip, TableFooter } from "@mui/material";
import { Table, TableBody, TableCell, TableHead, TableRow, TablePagination } from "@mui/material";
import SearchGlobal from 'app/shared/SearchGlobal/SearchGlobal';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useSelector } from 'react-redux';
import { config } from 'app/config/main';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { hasActionPermissionList } from 'app/utils/menuHelpers';

const ManageUsersWithHook = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [page, setPage] = useState(0);
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [current, setCurrent] = useState();
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const portal = useSelector(state => state?.user?.userData?.portal)

    const [createAccess, setCreate] = useState(false);
    const [updateAccess, setUpdate] = useState(false);
    const [deleteAccess, setDelete] = useState(false);
    const [statusAccess, setStatus] = useState(false);


    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0)
    }

    const token = useSelector(state => state.auth?.accessToken);

    const handleClick = (event, item) => {
        setCurrent(item)
        setAnchorEl(event?.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const loadUserData = async () => {
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }

        await fetch(`${config.apiUrl}/users`, options)
            .then(res => res.json())
            .then(res => {
                console.log(res)
                setData(res.data)
                setCount(res.count)
            })
    }

    const handleCreate = () => {
        navigate('/settings/manage-users-with-react-hook/create')
    }

    const handleUpdate = () => {
        setAnchorEl(null);
        navigate(`/settings/manage-users-with-react-hook/update/${current.id}`)
    }

    const handleStatus = async () => {
        try {
            const bodyData = {
                id: current.id,
                status: current.status === 1 ? 0 : 1
            }
            const options = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(bodyData)
            }
            await fetch(`${config.apiUrl}/users/status`, options)
                .then(res => res.json())
                .then((res) => {
                    console.log(res);
                    if (res.status === true) {
                        toast.success('Users status Updated Successfully')
                        loadUserData()
                    } else {
                        toast.error(res.message)
                    }
                    setAnchorEl(null);
                }, (err) => {
                    console.log(err)
                })
        } catch (error) {
            console.log(error)
        }
    }

    const handleDelete = async () => {
        try {
            const options = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }
            await fetch(`${config.apiUrl}/users/delete/${current.id}`, options)
                .then(res => res.json())
                .then(res => {
                    console.log(res);
                    if (res.status === true) {
                        toast.success('Users Deleted Successfully')
                        loadUserData();

                    } else {
                        toast.error(res.message);
                    }
                    setAnchorEl(null);
                }, (err) => {
                    console.log(err)
                })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        loadUserData();
        if (portal === 'admin') {
            setCreate(true)
            setUpdate(true)
            setDelete(true)
            setStatus(true)
        } else {
            hasActionPermissionList('user_create').then(res => { setCreate(res) })
            hasActionPermissionList('user_update').then(res => { setUpdate(res) })
            hasActionPermissionList('user_delete').then(res => { setDelete(res) })
            hasActionPermissionList('user_status').then(res => { setStatus(res) })
        }
    }, [])

    return (
        <Fragment>
            <Div sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant={"h2"} mb={5}>{`Manage Users (${count})`}</Typography>
                <SearchGlobal
                    sx={{
                        maxWidth: { xs: 240, md: 320 },
                        marginBottom: '2.5rem',
                        marginLeft: '1rem'
                    }}
                />
                {
                    createAccess === true && <Button variant="outlined" sx={{
                        marginLeft: 'auto',
                        marginBottom: '2.5rem',
                        borderRadius: '24px',
                        backgroundColor: '#fff',
                        ':hover': {
                            backgroundColor: '#e48b1a',
                            color: '#fff'
                        }
                    }} onClick={handleCreate}><AddIcon />Create</Button>
                }

            </Div>
            <Card>
                <CardContent>
                    <Div>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Role Name</TableCell>
                                    <TableCell>Mobile Number</TableCell>
                                    <TableCell>Email ID</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    data?.map((item) => {
                                        return (
                                            <TableRow>
                                                <TableCell>{item.name}</TableCell>
                                                <TableCell>{item.roleName}</TableCell>
                                                <TableCell>{item.mobileno}</TableCell>
                                                <TableCell>{item.email}</TableCell>
                                                <TableCell>{item.status === 1 ?
                                                    <p className='p-active'><span className='rounded-dot'></span>Active</p> :
                                                    <p className='p-inactive'><span className='rounded-dot'></span>Inactive</p>
                                                }</TableCell>
                                                {
                                                    (updateAccess === true || deleteAccess === true || statusAccess === true) && <TableCell>
                                                        <IconButton
                                                            aria-label="more"
                                                            id="long-button"
                                                            aria-controls={open ? 'long-menu' : undefined}
                                                            aria-expanded={open ? 'true' : undefined}
                                                            aria-haspopup="true"
                                                            onClick={(e) => handleClick(e, item)}
                                                        >
                                                            <MoreVertIcon />
                                                        </IconButton>
                                                        <Menu
                                                            id="fade-menu"
                                                            MenuListProps={{
                                                                'aria-labelledby': 'fade-button',
                                                            }}
                                                            anchorEl={anchorEl}
                                                            open={open}
                                                            onClose={handleClose}
                                                            TransitionComponent={Fade}
                                                        >
                                                            {updateAccess === true && <MenuItem onClick={() => handleUpdate()} >Update</MenuItem>}
                                                            {statusAccess === true && <MenuItem onClick={() => handleStatus()}>{current?.status === 1 ? 'Mark as Inactive' : 'Mark as Active'}</MenuItem>}
                                                            {deleteAccess === true && <MenuItem onClick={() => handleDelete()}>Delete</MenuItem>}
                                                        </Menu>
                                                    </TableCell>
                                                }

                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                            <TableFooter>
                                <TablePagination
                                    count={count}
                                    page={page}
                                    onPageChange={(event, newPage) => setPage(newPage)}
                                    rowsPerPage={rowsPerPage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </TableFooter>
                        </Table>
                    </Div>
                </CardContent>
            </Card>
        </Fragment>
    )
}

export default ManageUsersWithHook;