import React from "react";

import { getModulePermission } from "app/utils/menuHelpers";


import { RiHomeLine } from "react-icons/ri";
import { FaRegUser } from "react-icons/fa6";

import { PiNote } from "react-icons/pi";
import { LuArrowRightLeft } from "react-icons/lu";
import { LiaUserEditSolid } from "react-icons/lia";
import { IoSettingsOutline } from "react-icons/io5";
import { ReportingUserMenuIcon, UserMenuIcon } from "app/assets/svg/Svg";
import { GoHeart } from "react-icons/go";
import { LiaCrownSolid } from "react-icons/lia";
import { IoTicketOutline } from "react-icons/io5";
import { DashBoardIcon, MatchesIcon, ReportUsers, ReportsIcon, SettingsIcon, SubsIcon, TicketIcon, TransactionIcon, UserIcon } from "app/assets/svg/Svg";
let portal = sessionStorage.getItem('portal')
let USER_MENU = [
    {
      
        type: 'section',
    }
];

portal = null ?? 'admin';

if (portal === 'user') {
    await getModulePermission().then(res => { USER_MENU = res })
}

const ADMIN_MENU = [
    // {
    //     uri: "/settings/manage-roles",
    //     label: 'Manage Roles',
    //     type: "nav-item",
    //     icon: <ListAltIcon style={{fontSize :"24px"}} />
    // },
    // {
    //     uri: "/settings/manage-users",
    //     label: 'Manage Users',
    //     type: "nav-item",
    //     icon: <AccountCircleIcon style={{fontSize :"24px"}} />
    // },
    // {
    //     uri: "/settings/roles-permissions",
    //     label: 'Roles & Permissions',
    //     type: "nav-item",
    //     icon: <LockPersonIcon style={{fontSize :"24px"}} />
    // },
    // {
    //     uri: "/settings/activity-log",
    //     label: 'Activity Log',
    //     type: "nav-item",
    //     icon: <EditCalendarIcon style={{fontSize :"24px"}} />
    // },
    // {
    //     uri: "/change-password",
    //     label: 'Change Password',
    //     type: "nav-item",
    //     icon: <LockResetIcon style={{fontSize :"24px"}} />
    // },
    // {
    //     uri: "/settings/manage-users-with-react-hook",
    //     label: 'Manage Users with React Hook Form',
    //     type: "nav-item",
    //     icon: <WebhookIcon style={{fontSize :"24px"}} />
    // },
]

const menus = [
    {
        label: '-',
        type: 'section',
        children: [
            {
                uri: "/home",
                label: 'Dashboard',
                type: "nav-item",
                icon: <DashBoardIcon />
            },
           {
                uri: "/user-management",
                label: 'User Management',
                type: "nav-item",
                icon: <UserMenuIcon style={{fontSize :"24px"}} />
            },
           
            {
                uri: "/reporting-user",
                label: 'Reporting User',
                type: "nav-item",
                icon: <ReportingUserMenuIcon style={{fontSize :"24px"}} />
            },
           
            
            {
                uri: "/matches",
                label: 'Matches',
                type: "nav-item",
                icon: <MatchesIcon />
            },
            
            {
                uri: "/transactionlist",
                label: 'Transaction',

                type: "nav-item",
                icon: <TransactionIcon />
            },
            {
                uri: "/ticket",
                label: 'Tickets',

                type: "nav-item",
                icon: <TicketIcon/>
            },
            {
                uri: "/subscriptionsList",
                label: 'Subscriptions',
                type: "nav-item",
                icon: <SubsIcon/>
            },
            // {
            //     uri: "/collect-money",
            //     label: 'Collect Money',

            //     type: "nav-item",
            //     icon: <PiHandCoins style={{fontSize :"24px"}} />
            // },
            // {
            //     uri: "/bill-payments",
            //     label: 'Bill Payments',

            //     type: "nav-item",
            //     icon: <PiReceiptLight style={{fontSize :"24px"}} />
            // },
            {
                uri: "/settings",
                label: 'Settings',

                type: "nav-item",
                icon: <SettingsIcon/>
            },
            {
                uri: "/reports",
                label: 'Reports',
                type: "nav-item",
                icon: <ReportsIcon />
            },

            {
                uri: "/theme-helper",
                label: 'Theme Helper',

                type: "nav-item",
                icon: <PiNote style={{fontSize :"24px"}} />
            },
        ]
    },
    {
        label: '-',
        type: 'section',
        children: portal === "admin" ? ADMIN_MENU : USER_MENU
    }
];

export default menus;
