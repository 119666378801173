import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Skeleton from '@mui/material/Skeleton';
import {Card} from "@mui/material";
 
const HomeSkeleton = () => {
  useEffect(() => {});
  return (
    <div>
       
      <Box>
        <Grid container spacing={2}>
          <Grid item lg={7} spacing={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={5} spacing={2}>


                <Card sx={{ height: "220px" }}>

                  
         
                  <div className="accont_info">
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }}  />
                  <Skeleton variant="text" sx={{ fontSize: '0.5rem' }}  />
                  </div>
                  <div className="statement_info">
                  <Skeleton variant="rounded"  height={60} />
                  <Skeleton variant="text" sx={{ fontSize: '0.5rem' }}  />
                 
                  </div>
                </Card>
              </Grid>
              <Grid item xs={12} lg={7} spacing={2}>
                <Card className="qucik_access_card" sx={{ height: "220px" }}>
                <Skeleton variant="text" sx={{ fontSize: '1rem' }}  />
                  <div className="quick_acess">
                    <div className="text-center">
                      <div className="icon_bg">
                      <Skeleton variant="circular" width={62} height={62} />
                      </div>
                     
                      <Skeleton variant="text" sx={{ fontSize: '0.5rem' }}  />
                      <Skeleton variant="text" sx={{ fontSize: '0.5rem' }}  />
                    </div>
                    <div className="text-center">
                      <div className="icon_bg">
                      <Skeleton variant="circular" width={62} height={62} />
                      </div>
                     
                      <Skeleton variant="text" sx={{ fontSize: '0.5rem' }}  />
                      <Skeleton variant="text" sx={{ fontSize: '0.5rem' }}  />
                    </div>
                    <div className="text-center">
                      <div className="icon_bg">
                      <Skeleton variant="circular" width={62} height={62} />
                      </div>
                     
                      <Skeleton variant="text" sx={{ fontSize: '0.5rem' }}  />
                      <Skeleton variant="text" sx={{ fontSize: '0.5rem' }}  />
                    </div>
                    <div className="text-center">
                      <div className="icon_bg">
                      <Skeleton variant="circular" width={62} height={62} />
                      </div>
                     
                      <Skeleton variant="text" sx={{ fontSize: '0.5rem' }}  />
                      <Skeleton variant="text" sx={{ fontSize: '0.5rem' }}  />
                    </div>
                  </div>
                </Card>
              </Grid>
            </Grid>
            <Grid container xs={12}>
              <Grid item xs={12}>
                <Card sx={{ height: "670px", mt: 2 }}></Card>
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={5} xs={12}>
            <Card className="recent_transaction_card" sx={{ height: "895px" }}>
              <div className=" p-22 ">
                <div className="">
                <Skeleton variant="text" sx={{ fontSize: '1rem' }}  />
                <Skeleton variant="text" sx={{ fontSize: '0.5rem' }}  />
                </div>

                <div className="recent_transaction_header mt-10">
                <Skeleton variant="rounded" width={300} height={50} />
                   
                  <Skeleton variant="rounded" width={50} height={50} />
                     
                 
                </div>
              </div>

              <div className="recent_search_result">
                <div>
                  <div className="recent_months">
                  <p><Skeleton variant="text" sx={{ fontSize: '1rem', width:90 }}  /></p>
                  
                  </div>
                  <div className="main_results">
                  <div className="result_card space-between ">
                      <Grid lg={12} container className=" items-center">
                        <Grid items lg={2} className="">
                        <Skeleton variant="circular" width={45} height={45} /> 
                        </Grid>

                        <Grid lg={10} className="">
                          <h4> <Skeleton variant="text" sx={{ fontSize: '1rem' }}  /></h4>
                          <p> <Skeleton variant="text" sx={{ fontSize: '0.5rem' }}  /></p>
                        </Grid>
                      </Grid>
                     
                    </div>
                    <div className="result_card space-between ">
                      <Grid lg={12} container className=" items-center">
                        <Grid items lg={2} className="">
                        <Skeleton variant="circular" width={45} height={45} /> 
                        </Grid>

                        <Grid lg={10} className="">
                          <h4> <Skeleton variant="text" sx={{ fontSize: '1rem' }}  /></h4>
                          <p> <Skeleton variant="text" sx={{ fontSize: '0.5rem' }}  /></p>
                        </Grid>
                      </Grid>
                     
                    </div>
                    <div className="result_card space-between ">
                      <Grid lg={12} container className=" items-center">
                        <Grid items lg={2} className="">
                        <Skeleton variant="circular" width={45} height={45} /> 
                        </Grid>

                        <Grid lg={10} className="">
                          <h4> <Skeleton variant="text" sx={{ fontSize: '1rem' }}  /></h4>
                          <p> <Skeleton variant="text" sx={{ fontSize: '0.5rem' }}  /></p>
                        </Grid>
                      </Grid>
                     
                    </div>
                    <div className="result_card space-between ">
                      <Grid lg={12} container className=" items-center">
                        <Grid items lg={2} className="">
                        <Skeleton variant="circular" width={45} height={45} /> 
                        </Grid>

                        <Grid lg={10} className="">
                          <h4> <Skeleton variant="text" sx={{ fontSize: '1rem' }}  /></h4>
                          <p> <Skeleton variant="text" sx={{ fontSize: '0.5rem' }}  /></p>
                        </Grid>
                      </Grid>
                     
                    </div>
                    <div className="result_card space-between ">
                      <Grid lg={12} container className=" items-center">
                        <Grid items lg={2} className="">
                        <Skeleton variant="circular" width={45} height={45} /> 
                        </Grid>

                        <Grid lg={10} className="">
                          <h4> <Skeleton variant="text" sx={{ fontSize: '1rem' }}  /></h4>
                          <p> <Skeleton variant="text" sx={{ fontSize: '0.5rem' }}  /></p>
                        </Grid>
                      </Grid>
                     
                    </div>
                    <div className="result_card space-between ">
                      <Grid lg={12} container className=" items-center">
                        <Grid items lg={2} className="">
                        <Skeleton variant="circular" width={45} height={45} /> 
                        </Grid>

                        <Grid lg={10} className="">
                          <h4> <Skeleton variant="text" sx={{ fontSize: '1rem' }}  /></h4>
                          <p> <Skeleton variant="text" sx={{ fontSize: '0.5rem' }}  /></p>
                        </Grid>
                      </Grid>
                     
                    </div>
                  </div>
                </div>
                <div>
                  <div className="recent_months">
                  <p><Skeleton variant="text" sx={{ fontSize: '1rem', width:90 }}  /></p>
                  </div>
                  <div className="main_results">
                  <div className="result_card space-between ">
                      <Grid lg={12} container className=" items-center">
                        <Grid items lg={2} className="">
                        <Skeleton variant="circular" width={45} height={45} /> 
                        </Grid>

                        <Grid lg={10} className="">
                          <h4> <Skeleton variant="text" sx={{ fontSize: '1rem' }}  /></h4>
                          <p> <Skeleton variant="text" sx={{ fontSize: '0.5rem' }}  /></p>
                        </Grid>
                      </Grid>
                     
                    </div>
                    <div className="result_card space-between ">
                      <Grid lg={12} container className=" items-center">
                        <Grid items lg={2} className="">
                        <Skeleton variant="circular" width={45} height={45} /> 
                        </Grid>

                        <Grid lg={10} className="">
                          <h4> <Skeleton variant="text" sx={{ fontSize: '1rem' }}  /></h4>
                          <p> <Skeleton variant="text" sx={{ fontSize: '0.5rem' }}  /></p>
                        </Grid>
                      </Grid>
                     
                    </div>
                    <div className="result_card space-between ">
                      <Grid lg={12} container className=" items-center">
                        <Grid items lg={2} className="">
                        <Skeleton variant="circular" width={45} height={45} /> 
                        </Grid>

                        <Grid lg={10} className="">
                          <h4> <Skeleton variant="text" sx={{ fontSize: '1rem' }}  /></h4>
                          <p> <Skeleton variant="text" sx={{ fontSize: '0.5rem' }}  /></p>
                        </Grid>
                      </Grid>
                     
                    </div>
                    <div className="result_card space-between ">
                      <Grid lg={12} container className=" items-center">
                        <Grid items lg={2} className="">
                        <Skeleton variant="circular" width={45} height={45} /> 
                        </Grid>

                        <Grid lg={10} className="">
                          <h4> <Skeleton variant="text" sx={{ fontSize: '1rem' }}  /></h4>
                          <p> <Skeleton variant="text" sx={{ fontSize: '0.5rem' }}  /></p>
                        </Grid>
                      </Grid>
                     
                    </div>
                    <div className="result_card space-between ">
                      <Grid lg={12} container className=" items-center">
                        <Grid items lg={2} className="">
                        <Skeleton variant="circular" width={45} height={45} /> 
                        </Grid>

                        <Grid lg={10} className="">
                          <h4> <Skeleton variant="text" sx={{ fontSize: '1rem' }}  /></h4>
                          <p> <Skeleton variant="text" sx={{ fontSize: '0.5rem' }}  /></p>
                        </Grid>
                      </Grid>
                     
                    </div>
                    <div className="result_card space-between ">
                      <Grid lg={12} container className=" items-center">
                        <Grid items lg={2} className="">
                        <Skeleton variant="circular" width={45} height={45} /> 
                        </Grid>

                        <Grid lg={10} className="">
                          <h4> <Skeleton variant="text" sx={{ fontSize: '1rem' }}  /></h4>
                          <p> <Skeleton variant="text" sx={{ fontSize: '0.5rem' }}  /></p>
                        </Grid>
                      </Grid>
                     
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default HomeSkeleton;
