import React from 'react';
import {Search, SearchIconWrapper, StyledInputBase} from "./style";
import { RiSearchLine } from "react-icons/ri";
import { CiSearch } from "react-icons/ci";
const SearchGlobal = ({sx}) => {
    return (
        <Search sx={sx}>
            <SearchIconWrapper>
                <RiSearchLine size={20} color='#81869C'/>
            </SearchIconWrapper>

            <StyledInputBase
                placeholder="Search"
                inputProps={{'aria-label': 'search'}}
            />
        </Search>
    );
};

export default SearchGlobal;
