import React, { Fragment, useEffect, useState } from "react";
import { FiArrowRight } from "react-icons/fi";
import Div from "@jumbo/shared/Div";
import MenuItem from "@mui/material/MenuItem";
import {
  CardContent,
  Card,
  Typography,
  Button,
  IconButton,
  Select,
  TextField,
} from "@mui/material";
import * as yup from "yup";
import { Form, Formik, useFormik } from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import BlockIcon from "@mui/icons-material/Block";
import EastIcon from "@mui/icons-material/East";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { config } from "app/config/main";
import { ToastContainer, toast } from "react-toastify";
import ListIcon from "@mui/icons-material/List";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import { generatePassword } from "app/utils/formatHelpers";
import JumboAppTextField from "@jumbo/components/JumboAppTextField/JumboAppTextField";
import { ToastServices } from "app/services/toastServices";

const validationSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  mobileno: yup.string().required("Mobil No is required"),
  role_id: yup.string().required("Please select Role"),
});

const CreateUser = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const token = useSelector((state) => state.auth?.accessToken);
  const [role, setRole] = useState();
  const [roleData, setRoleData] = useState([]);
  const [password, setPassword] = useState("");

  const getRoleData = async () => {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    await fetch(`${config.apiUrl}/role/list`, options)
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setRoleData(res.data);
      });
  };

  const handleSubmit = async (data) => {
    try {
      const bodyData = {
        name: data.name,
        email: data.email,
        mobileno: data.mobileno,
        password: data.password,
        role_id: role,
      };
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(bodyData),
      };
      await fetch(`${config.apiUrl}/users/create`, options)
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
          if (res.status === true) {
            ToastServices.SucessToast("New User Created Successfully");
            navigate("/settings/manage-users");
          } else {
            ToastServices.ErroToast(res.message);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleCanel = () => {
    navigate("/settings/manage-users");
  };

  const handlePrevious = () => {
    navigate("/settings/manage-users");
  };

  const handlePassword = () => {
    const genPassword = generatePassword();
    setPassword(genPassword);
  };

  useEffect(() => {
    getRoleData();
  }, []);

  return (
    <Fragment>
      <Div className="d-flex items-center space-between mb-30">
        <Typography className="page_header" >{"Create User"}</Typography>
        <Button
        startIcon={ <ListIcon />}
          variant="outlined"
          className="link_btn"
          onClick={handlePrevious}
        >
         
          List
        </Button>
      </Div>

      <Div>
        <Card sx={{ width: 650 }}>
          <CardContent>
            <Formik
              validateOnChange={true}
              initialValues={{
                name: "",
                email: "",
                mobileno: "",
                password: password,
                role_id: "",
              }}
              // validationSchema={validationSchema}
              onSubmit={(data, { setSubmitting, resetForm }) => {
                console.log(data);
                setSubmitting(true);
                handleSubmit(data);
                setSubmitting(false);
                resetForm({
                  data: {
                    name: "",
                    email: "",
                    mobileno: "",
                    password: "",
                    role_id: "",
                  },
                });
              }}
            >
              {({ isSubmitting, values, handleChange }) => (
                <Form>
                  <Div sx={{ mb: 3, mt: 1, width: 450 }}>
                    <FormControl sx={{ m: 1, width: 500 }} variant="outlined">
                      {/* <InputLabel>Enter Name<span className='text-danger'>*</span></InputLabel> */}
                      <Typography className="from_label">ENTER NAME</Typography>
                      <JumboAppTextField
                        fullWidth={true}
                        type={"text"}
                        required={true}
                        
                        name="name"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </Div>
                  <Div sx={{ mb: 3, mt: 1, width: 450 }}>
                    <FormControl sx={{ m: 1, width: 500 }}>
                    <Typography className="from_label">SELECT ROLE</Typography>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        
                        name="role_id"
                        value={role}
                        required={true}
                        onChange={(event) => {
                          setRole(event.target.value);
                        }}
                      >
                        {roleData?.length > 0 &&
                          roleData?.map((item) => {
                            return (
                              <MenuItem value={item?.id}>{item?.name}</MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Div>

                  <Div sx={{ mb: 3, mt: 1, width: 450 }}>
                    <FormControl sx={{ m: 1, width: 500 }} variant="outlined">
                      {/* <InputLabel>Enter Mobile No</InputLabel> */}
                      <Typography className="from_label">ENTER MOBILE NO</Typography>
                      <JumboAppTextField
                        fullWidth={true}
                        type={"text"}
                        required={true}
                       
                        name="mobileno"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </Div>
                  <Div sx={{ mb: 3, mt: 1, width: 450 }}>
                    <FormControl sx={{ m: 1, width: 500 }} variant="outlined">
                      {/* <InputLabel>Enter Email ID</InputLabel> */}
                      <Typography className="from_label">ENTER EMAIL ID</Typography>
                      <JumboAppTextField
                        fullWidth={true}
                        type={"text"}
                        required={true}
                        
                        name="email"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </Div>
                  <Div sx={{ mb: 3, mt: 1, width: 450 }}>
                    <FormControl sx={{ m: 1, width: 500 }}>
                      {/* <InputLabel id="demo-simple-select-label">Enter Password</InputLabel> */}
                      <Typography className="from_label">ENTER PASSWORD</Typography>
                      <JumboAppTextField
                        fullWidth={true}
                        type={showPassword ? "text" : "password"}
                        value={password}
                        name="password"
                        required={true}
                        onChange={handleChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword((show) => !show)}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                       
                      />
                    </FormControl>
                  </Div>
                  <Div>
                    <Button
                      variant="outlined"
                      className="generate_pass"
                      onClick={handlePassword}
                    >
                      Generate Passowrd
                    </Button>
                  </Div>
                  <Div
                    sx={{
                      display: "flex",
                      marginTop: "1.5rem",
                      marginLeft: "auto",
                      justifyContent: "right",
                    }}
                  >
                    <Button
                      endIcon={<BlockIcon
                       
                      />}
                      variant="outlined"
                      className="activity_cancel"
                      onClick={handleCanel}
                    >
                      Cancel
                      
                    </Button>
                    <Button
                    endIcon={<FiArrowRight />}
                      type="submit"
                      className="activity_submit"
                    >
                      Submit
                    
                    </Button>
                  </Div>
                </Form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Div>
    </Fragment>
  );
};

export default CreateUser;
