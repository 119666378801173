import React, { Fragment, useEffect, useState } from 'react';
import Div from "@jumbo/shared/Div";
import MenuItem from "@mui/material/MenuItem";
import { CardContent, Card, Typography, Button, IconButton, Select, } from "@mui/material";
import BlockIcon from '@mui/icons-material/Block';
import EastIcon from '@mui/icons-material/East';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { config } from 'app/config/main';
import { toast } from 'react-toastify';
import ListIcon from '@mui/icons-material/List';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import { generatePassword } from 'app/utils/formatHelpers';
import {useForm} from 'react-hook-form';

const CreateUserWithHook = () => {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const token = useSelector(state => state.auth?.accessToken);
    const [role, setRole] = useState()
    const [roleData, setRoleData] = useState([])
    const [password, setPassword] = useState('');
    const { register, formState: { errors }, handleSubmit, setValue } = useForm()

    const getRoleData = async () => {
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }

        await fetch(`${config.apiUrl}/role/list`, options)
            .then(res => res.json())
            .then(res => {
                console.log(res)
                setRoleData(res.data)

            })
    }

    const onSignin = async (data) => {
        console.log(data);
        try {
            const bodyData = {
                name: data.name,
                email: data.email,
                mobileno: data.mobileno,
                password: data.password,
                role_id: data.role_id
            }
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(bodyData)
            }
            await fetch(`${config.apiUrl}/users/create`, options)
                .then(res => res.json())
                .then(res => {
                    console.log(res)
                    if (res.status === true) {
                        toast.success('New User Created Successfully')
                        navigate('/settings/manage-users')
                    } else {
                        toast.error(res.message)
                    }
                })
        } catch (error) {
            console.log(error)
        }
    }

    const handleCanel = () => {
        navigate('/settings/manage-users')
    }

    const handlePrevious = () => {
        navigate('/settings/manage-users')
    }

    const handlePassword = () => {
        const genPassword = generatePassword();
        setPassword(genPassword);
        setValue('password', genPassword);
    }

    useEffect(() => {
        getRoleData()
    }, [])

    return (
        <Fragment>
            <Div sx={{ display: 'flex' }}>
                <Typography variant={"h2"} mb={5}>{'Create User'}</Typography>
                <Button variant="outlined"
                    onClick={handlePrevious}
                    sx={{
                        marginLeft: 'auto',
                        marginBottom: '2.5rem',
                        borderRadius: '24px',
                        backgroundColor: '#fff',
                        ':hover': {
                            backgroundColor: '#e48b1a',
                            color: '#fff'
                        }
                    }}><ListIcon />List</Button>
            </Div>

            <Div>
                <Card sx={{ width: 650 }}>
                    <CardContent>
                        
                                    <form onSubmit={handleSubmit(onSignin)}>
                                        <Div sx={{ mb: 3, mt: 1, width: 450 }}>
                                            <FormControl sx={{ m: 1, width: 500 }} variant="outlined">
                                                <InputLabel>Enter Name<span className='text-danger'>*</span></InputLabel>
                                                <OutlinedInput
                                                    fullWidth={true}
                                                    type={'text'}
                                                    label="Enter Name"
                                                    name='name'
                                                    {...register('name', { required: true })}
                                                />
                                                <span className='text-danger'>{errors.name && 'Name is Required'}</span>
                                            </FormControl>
                                        </Div>
                                        <Div sx={{ mb: 3, mt: 1, width: 450 }}>
                                            <FormControl sx={{ m: 1, width: 500 }}>
                                                <InputLabel id="demo-simple-select-label">Select Role<span className='text-danger'>*</span></InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    label="Select Role"
                                                    name='role_id'
                                                    value={role}
                                                    onChange={(event) => {setRole(event.target.value)}}
                                                    {...register('role_id', { required: true })}
                                                >
                                                    {
                                                        roleData?.length > 0 && roleData?.map((item) => {
                                                            return (
                                                                <MenuItem value={item?.id}>{item?.name}</MenuItem>
                                                            )
                                                        })
                                                    }


                                                </Select>
                                                <span className='text-danger'>{errors.role_id && 'Role is Required'}</span>
                                            </FormControl>
                                        </Div>

                                        <Div sx={{ mb: 3, mt: 1, width: 450 }}>
                                            <FormControl sx={{ m: 1, width: 500 }} variant="outlined">
                                                <InputLabel>Enter Mobile No<span className='text-danger'>*</span></InputLabel>
                                                <OutlinedInput
                                                    fullWidth={true}
                                                    type={'text'}
                                                    label="Enter Mobile No"
                                                    name='mobileno'
                                                    {...register('mobileno', { required: true })}
                                                />
                                                <span className='text-danger'>{errors.mobileno && 'Mobile No is Required'}</span>
                                            </FormControl>
                                        </Div>
                                        <Div sx={{ mb: 3, mt: 1, width: 450 }}>
                                            <FormControl sx={{ m: 1, width: 500 }} variant="outlined">
                                                <InputLabel>Enter Email ID<span className='text-danger'>*</span></InputLabel>
                                                <OutlinedInput
                                                    fullWidth={true}
                                                    type={'text'}
                                                    label="Enter Email"
                                                    name='email'
                                                    {...register('email', { required: true })}
                                                />
                                                <span className='text-danger'>{errors.email && 'Email is Required'}</span>
                                            </FormControl>
                                        </Div>
                                        <Div sx={{ mb: 3, mt: 1, width: 450 }}>
                                            <FormControl sx={{ m: 1, width: 500 }}>
                                                <InputLabel id="demo-simple-select-label">Enter Password<span className='text-danger'>*</span></InputLabel>
                                                <OutlinedInput
                                                    fullWidth={true}
                                                    type={showPassword ? 'text' : 'password'}
                                                    value={password}
                                                    name='password'
                                                   
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={() => setShowPassword((show) => !show)}
                                                                edge="end"
                                                            >
                                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    label="Password"
                                                    {...register('password', { required: true })}
                                                />
                                                <span className='text-danger'>{errors.password && 'password is Required'}</span>
                                            </FormControl>
                                        </Div>
                                        <Div>
                                            <Button variant="outlined" sx={{
                                                borderRadius: '24px',
                                                backgroundColor: '#fff',
                                                backgroundColor: '#fff',
                                                color: '#e48b1a',
                                                marginLeft: '1rem',
                                                textTransform: 'capitalize',
                                                ':hover': {
                                                    backgroundColor: '#e48b1a',
                                                    color: '#fff',
                                                }
                                            }} onClick={handlePassword}>Generate Passowrd</Button>
                                        </Div>
                                        <Div sx={{ display: 'flex', marginTop: '1.5rem', marginLeft: 'auto', justifyContent: 'right' }}>
                                            <Button variant="outlined" sx={{
                                                borderRadius: '24px',
                                                backgroundColor: '#B0BEC5',
                                                color: '#000000DE',
                                                textTransform: 'capitalize',
                                                ':hover': {
                                                    backgroundColor: '#B0BEC5',
                                                    color: '#000000DE',
                                                }
                                            }} onClick={handleCanel}>Cancel<BlockIcon sx={{ marginLeft: '5px', width: '15px', height: '15px' }} /></Button>
                                            <Button type='submit' sx={{
                                                borderRadius: '24px',
                                                backgroundColor: '#fff',
                                                backgroundColor: '#e48b1a',
                                                color: '#fff',
                                                marginLeft: '1rem',
                                                textTransform: 'capitalize',
                                                ':hover': {
                                                    backgroundColor: '#e48b1a',
                                                    color: '#fff',
                                                }
                                            }}>Submit<EastIcon sx={{ marginLeft: '5px' }} /></Button>
                                        </Div>
                                    </form>
                                
                    </CardContent>
                </Card>
            </Div>
        </Fragment>
    )
}

export default CreateUserWithHook;