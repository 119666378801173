import React from 'react';
import {Link as RouterLink, useLocation} from "react-router-dom";
import Link from '@mui/material/Link';
import ListItemButton from "@mui/material/ListItemButton";
import {ListItemIcon} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import CircleIcon from '@mui/icons-material/Circle';
import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import {SIDEBAR_VIEWS} from "@jumbo/utils/constants/layout";
import {useTranslation} from "react-i18next";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
const menuBefore = {
    content: `""`,
    position: 'absolute',
    height: '20px !important',
    width: '20px !important',
   
    bottom: '-20px !important',
    right: '0 !important',
    borderTopRightRadius: '20px !important',
    boxShadow: '5px -5px 0px 5px #f9f8ff !important',
}
const menuAfter = {
    content: `""`,
    position: 'absolute',
    height: '20px',
    width: '20px !important',
    
    top: '-20px !important',
    right: '0 !important',
    borderBottomRightRadius: '20px !important',
    boxShadow: '5px 5px 0px 5px #f9f8ff !important',
}
const JumboNavItem = ({item, isNested, translate}) => {
    const location = useLocation();
    const {sidebarOptions} = useJumboLayoutSidebar();
    const {t} = useTranslation();

    const isMiniAndClosed = React.useMemo(() => {
        return sidebarOptions?.view === SIDEBAR_VIEWS.MINI && !sidebarOptions?.open;
    }, [sidebarOptions.view, sidebarOptions.open]);

    const label = React.useMemo(() => {
        return translate ? t(item.label) : item.label;
    }, [item, translate, t]);

    if (!item) return null;

    return (
        <ListItemButton
        disableRipple
           
           className={(location.pathname === item.uri) ? "heartsync_list activeClass" : "heartsync_list" }
            component={"li"}
            sx={{
                p: 0,
                overflow: 'visible',
                borderRadius: isMiniAndClosed ? '50%' : '0 0px 0px 0',
                margin: isMiniAndClosed ? '0 auto' : '0',
                ...(isMiniAndClosed) ? {width: 40, height: 40, justifyContent: 'center'} : {},
                
                '&:hover': {
                    color: theme => theme.palette.nav.action.hover,
                    backgroundColor: theme => theme.palette.nav.background.hover,
                    ...(!isMiniAndClosed) ? {'&::after': {
                            ...menuAfter,
                            backgroundColor: theme => theme.palette.nav.tick.hover,
                        }}  : {},
                        ...(!isMiniAndClosed) ? {'&::before': {
                            ...menuBefore,
                            backgroundColor: theme => theme.palette.nav.tick.hover,
                        }}  : {},  

                },
                ...(location.pathname === item.uri) ? {
                    color: theme => theme.palette.nav.action.active,
                    backgroundColor: theme => theme.palette.nav.background.active,
                    ...(!isMiniAndClosed) ? {'&::after': {
                        ...menuAfter,
                        backgroundColor: theme => theme.palette.nav.tick.hover,
                    }}  : {},
                    ...(!isMiniAndClosed) ? {'&::before': {
                        ...menuBefore,
                        backgroundColor: theme => theme.palette.nav.tick.hover,
                    }}  : {}, 

                    
                } : {},
            }}
        >
            <Link className='sidenav_links' underline={"none"} component={RouterLink} to={item.uri} {...(item.target ? {target: item.target} : {})}
                  sx={{
                      flex: 1,
                      display: "flex",
                      alignItems: "center",
                      overflow: "hidden",
                      position: "relative",
                      color: 'inherit',
                      fontSize:"19px",
                      padding:"16px",
                      
                      p: theme => !isMiniAndClosed ? theme.spacing(1.9, 3.75) : 0,
                      ...(isMiniAndClosed) ? {justifyContent: 'center'} : {},
                  }}
            >

                <ListItemIcon sx={{minWidth: isMiniAndClosed ? 20 : 32, color: 'inherit'}}>
                    {
                        isNested ?
                            <CircleIcon sx={{fontSize: 6, ml: 1}}/>
                            :
                            item.icon
                           
                    }
                </ListItemIcon>
                {
                    !isMiniAndClosed &&
                    <ListItemText
                    className='sidnav_links_item'
                        primary={label}
                        sx={{
                            m: 0,
                            fontSize:"18px",
                            '& .MuiTypography-root': {
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }
                        }}
                    />
                }
                
                   
                
                
            </Link>
        </ListItemButton>
    );
};

export default JumboNavItem;
