import React from "react";
import Home from "../pages/app-pages/home";
import Page from "@jumbo/shared/Page";
import PermissionList from "../pages/app-pages/permissionList";
import ManageRoles from "../pages/app-pages/manageRoles/ManageRoles";
import ManageUsers from "../pages/app-pages/manageUsers/ManageUsers";
import RolesList from "app/pages/app-pages/rolesList/RolesList";
import ChangePassword from "app/pages/app-pages/changePassword/ChangePassword";
import ActivityLog from "app/pages/app-pages/activityLog/ActivityLog";
import NotificationList from "app/pages/app-pages/notificationList/NotificationList";
import CreateRole from "app/pages/app-pages/manageRoles/CreateRole/CreateRole";
import UpdateRole from "app/pages/app-pages/manageRoles/UpdateRole/UpdateRole";
import CreateUser from "app/pages/app-pages/manageUsers/CreateUser/CreateUser";
import UpdateUser from "app/pages/app-pages/manageUsers/UpdateUser/UpdateUser";
import EditProfile from "app/pages/app-pages/editProfile/EditProfile";
import ManageUsersWithHook from "app/pages/app-pages/manageUsersWithHookForm/ManageUsersWithHook";
import CreateUserWithHook from "app/pages/app-pages/manageUsersWithHookForm/CreateUser/CreateUserWithHook";
import UpdateUserWithHook from "app/pages/app-pages/manageUsersWithHookForm/UpdateUser/UpdateUsersWithHook";
import TransactionList from "app/pages/app-pages/transactions/transactionList/transactionList";

import HomeSkeleton from "app/pages/skeleton/dashboard_skeleton";
import BackdropLoader from "app/pages/app-pages/component/loader";
import SubscriptionsList from "app/pages/app-pages/Subscriptions/SubscriptionsList/SubscriptionsList";
import ThemeHelper from "app/pages/app-pages/test-page/test";
import UserManagement from "app/pages/app-pages/userManagement/userManagement";
import ReportingUser from "app/pages/app-pages/reportingUser/ReportingUser";
 

const appRoutes = [
    {
        path: "/",
        element: <Page component={Home} />
    },
    {
        path: "/home",
        element: <Page component={Home} />
    },
    {
        path: "/subscriptionsList",
        element: <Page component={SubscriptionsList} />
    },
   
    {
        path: "/transactionlist",
        element: <Page component={TransactionList} />
    },
    
   
    {
        path: 'settings/roles-permissions',
        element: <Page component={RolesList} />
    },
    {
        path: 'settings/roles-permissions/permission-list/:id',
        element: <Page component={PermissionList} />
    },
    {
        path: 'settings/manage-roles',
        element: <Page component={ManageRoles} />
    },
    {
        path: 'settings/manage-roles/create',
        element: <Page component={CreateRole} />
    },
    {
        path: 'settings/manage-roles/update/:id',
        element: <Page component={UpdateRole} />
    },
    {
        path: 'settings/manage-users',
        element: <Page component={ManageUsers} />
    },
    {
        path: 'settings/manage-users/create',
        element: <Page component={CreateUser} />
    },
    {
        path: 'settings/manage-users/update/:id',
        element: <Page component={UpdateUser} />
    },
    {
        path: "/change-password",
        element: <Page component={ChangePassword} />
    },
    {
        path: "settings/activity-log",
        element: <Page component={ActivityLog} />
    },
    {
        path: "notification-details",
        element: <Page component={NotificationList} />
    },  
    {
        path: "change-profile",
        element: <Page component={EditProfile} />
    },
    {
        path: 'settings/manage-users-with-react-hook',
        element: <Page component={ManageUsersWithHook} />
    },
    {
        path: 'settings/manage-users-with-react-hook/create',
        element: <Page component={CreateUserWithHook} />
    },
    {
        path: 'settings/manage-users-with-react-hook/update/:id',
        element: <Page component={UpdateUserWithHook} />
    },
    {
        path: 'home/skeleton',
        element: <Page component={HomeSkeleton} />
    },
    {
        path: "/loader",
        element: <Page component={BackdropLoader} />
    },
    {
        path: "/user-management",
        element: <Page component={UserManagement} />
    },
    {
        path: "/theme-helper",
        element: <Page component={ThemeHelper} />
    },
    {
        path: "/reporting-user",
        element: <Page component={ReportingUser} />
    }
];

const adminRoutes = [
   
   
]

export  {appRoutes, adminRoutes};
