 
import {
  TableCell,
  TableRow,
  Typography,
 } from "@mui/material";
import React, { useState , useEffect} from "react";
import NO_RECORD_FOUND from "../../../assets/no_records_found.png";

 
const NoRecordsFound = ({ tablecell }) => {
  
  return (
    
   <>
                     <TableRow align="center"> 
                    
                        <TableCell align="center" colSpan={tablecell}>
                  <div sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', placeItems: 'center', height: '100vh',}}>
                  <Typography variant={'h2'} className={'text-center'} sx={{alignItems: 'center'}}> No Record Found </Typography> 
                    <img width="200px" height="200px" src={NO_RECORD_FOUND} alt="your-image-alt-text" />
                  </div>
                  </TableCell>
                  
                  </TableRow>  
  </>   
            
  )
}

export default NoRecordsFound;
