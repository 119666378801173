import React, { useState } from "react";
import Div from "@jumbo/shared/Div";
import {
  Card,
  CardContent,
  Grid,
  Pagination,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
  Button,
  Menu,
  MenuItem, 
  IconButton,
  TableSortLabel, 
  Tabs, 
  Tab, 
  Box, 
  Tooltip, 
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
 import PropTypes from "prop-types";
import { CiSearch } from "react-icons/ci";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeletePopup from "../component/deleteModal";
import SkeletonTableLoader from "../component/skeleton_loader/skeletonTableLoader";
import NoRecordsFound from "../component/noRecordsFound";
import DyanamicDatePicker from "../component/DatePicker/DyanamicDatePicker";
import {
  FilterIcon,
  RefreshIcon,
  EyeIcon,
  PremiumIcon,
} from "app/assets/svg/Svg";
import img1 from "../../../assets/user_img/img1.png";
import img2 from "../../../assets/user_img/img2.png";
import img3 from "../../../assets/user_img/img3.png";
import img4 from "../../../assets/user_img/img4.png";

const UserManagement = () => {
 
  const [value, setValue] = React.useState(0);
  const [value1, setValue1] = React.useState(0);

  const tabhandleChange = (event, newValue) => {
    setValue(newValue);
  };
  const tab2handleChange = (event, newValue) => {
    setValue1(newValue);
  };

   

  const TableData = [
    {
      userid: "923738",
      created_at: "24-06-2023",
      name: "Thanasekaran",
      emailid: "thana1234@gmail.com",
      phoneno: "7234567234",
      gender: "Male",
      dob: "23-06-1999",
      status: 1,
      premium: 1,
      img: img1,
    },
    {
      userid: "143738",
      created_at: "24-06-2023",
      name: "Kamali",
      emailid: "kamali1234@gmail.com",
      phoneno: "6345567234",
      gender: "Female",
      dob: "11-09-1997",
      status: 0,
      premium: 0,
      img: img2,
    },
    {
      userid: "583738",
      created_at: "24-06-2023",
      name: "David Raj",
      emailid: "david1234@gmail.com",
      phoneno: "8245567234",
      gender: "Male",
      dob: "23-06-1986",
      status: 1,
      premium: 1,
      img: img4,
    },
    {
      userid: "163738",
      created_at: "23-06-2023",
      name: "Meenatchi",
      emailid: "meena1234@gmail.com",
      phoneno: "7256567234",
      gender: "Female",
      dob: "22-06-1998",
      status: 2,
      premium: 0,
      img: img3,
    },
  ];

  const [filteredData, setFilteredData] = useState(TableData);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setLoader(false);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDelete = () => {
    setAnchorEl(null);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const [page, setPage] = React.useState(10);

  const handleChangePage = (event) => {
    setPage(event.target.value);
  };

  return (
    <section className="current_beneficiaty">
      <DeletePopup open={isModalOpen} handleClose={handleCloseModal} />
{/*************** first block tabs and button   ******************* */}
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Box>
            <Box>
              <Tabs
                
                className="custom-tabs-2"
                value={value1}
                onChange={tab2handleChange}
                aria-label="basic tabs example"
              >
                <Tab label={`All Users (300)`} {...a11yProps(0)} />
                <Tab label={`Normal Users (182)`} {...a11yProps(1)} />
                <Tab label={`Prime Users (118)`} {...a11yProps(2)} />
              </Tabs>
            </Box>
          </Box>
        </Grid>
        <Grid item>
          {/* Button component on the right */}
          <Button className="outlined_btn ml-10" variant="outlined">
            {" "}
            Export{" "}
          </Button>
        </Grid>
      </Grid>

{/***************  second block tabs, icons, datepicker, searchbar,    ******************* */}

      <div className="d-flex items-center flex-wrap  mt-30 mb-25">
        <Box className="setwidth">
          <Tabs
            indicatorColor="secondary"
            className="custom-tabs"
            value={value}
            onChange={tabhandleChange}
            aria-label="basic tabs example"
          >
            <Tab label="All (13)" {...a11yProps(0)} />
            <Tab label="Active(8)" {...a11yProps(1)} />
            <Tab label="Inactive(3)" {...a11yProps(2)} />
            <Tab label="Blocked(2)" {...a11yProps(3)} />
          </Tabs>
         
          </Box>

        <Tooltip title="SVG icon Refresh">
           <IconButton className="ml-60 icon_btn"  variant="outlined">
             <RefreshIcon /> 
          </IconButton>
        </Tooltip>
        <Tooltip title="SVG icon Filter">
           <IconButton className="ml-10 icon_btn " variant="outlined">
            <FilterIcon /> 
          </IconButton>
        </Tooltip>

        <div className="user ml-10">
          <DyanamicDatePicker />
        </div>

        <OutlinedInput
          className="search_bar ml-10"
          id="outlined-adornment-password"
          placeholder="Search by Keyword"
          startAdornment={
            <InputAdornment position="start">
              <IconButton edge="start">
                <CiSearch />
              </IconButton>
            </InputAdornment>
          }
        />
      </div>

      <Card className="responsivecard">
        <CardContent>
          <Div> 

{/***************  table   ******************* */}
            
            <Table className="common_table" id="tablePopover">
              <TableHead>
                <TableRow>
                  <TableCell>
                    {" "}
                    <TableSortLabel>User Id</TableSortLabel>{" "}
                  </TableCell>

                  <TableCell>
                    <TableSortLabel>Joined Date</TableSortLabel>{" "}
                  </TableCell>

                  <TableCell>
                    {" "}
                    <TableSortLabel> Name </TableSortLabel>
                  </TableCell>

                  <TableCell>
                    <TableSortLabel> Email Id</TableSortLabel>{" "}
                  </TableCell>

                  <TableCell>
                    {" "}
                    <TableSortLabel>Phone No</TableSortLabel>{" "}
                  </TableCell>

                  <TableCell>
                    {" "}
                    <TableSortLabel>Gender</TableSortLabel>
                  </TableCell>

                  <TableCell>
                    {" "}
                    <TableSortLabel>Date of Birth</TableSortLabel>
                  </TableCell>

                  <TableCell>Status</TableCell>

                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  // Display the skeleton loader while data is loading
                  <SkeletonTableLoader tablecell="9" row="8" />
                ) : filteredData.length === 0 ? (
                  <NoRecordsFound tablecell="7" />
                ) : (
                  filteredData.map((item, i) => {
                    return (
                      <TableRow key={i}>
                        <TableCell>{item?.userid}</TableCell>
                        <TableCell>{item?.created_at}</TableCell>
                        <TableCell >
                          <div
                          className="profile_parent"
                            
                          >
                            <img
                              src={`${item?.img}`}
                              alt="img"
                              className="profile_bg"
                            />
                            {item?.premium === 1 ? (
                              <PremiumIcon className="premium_bg" />
                            ) : (
                              ""
                            )}
                            <span className="ml-5">{item?.name}</span>
                          </div>
                        </TableCell>
                        <TableCell>{item?.emailid}</TableCell>
                        <TableCell>{item?.phoneno}</TableCell>
                        <TableCell>{item?.gender}</TableCell>
                        <TableCell>{item?.dob}</TableCell>
                        <TableCell>
                          {item?.status === 1 ? (
                            <span className="status-active">Active</span>
                          ) : item?.status === 0 ? (
                            <span className="status-inactive">Inactive</span>
                          ) : item?.status === 2 ? (
                            <span className="status-blocked">Blocked</span>
                          ) : (
                            ""
                          )}
                        </TableCell>

                        <TableCell>
                          <Tooltip title=" View ">
                            {" "}
                            <IconButton
                              className="ml-10 svgtable_icon_btn"
                              variant="outlined"
                            >
                              {" "}
                              <EyeIcon />{" "}
                            </IconButton>
                          </Tooltip>

                          <Tooltip title="Actions " className="ml-10">
                            <IconButton
                              className="table_icon_btn"
                              id="demo-positioned-button"
                              aria-controls={
                                open ? "demo-positioned-menu" : undefined
                              }
                              aria-haspopup="true"
                              aria-expanded={open ? "true" : undefined}
                              onClick={handleClick}
                            >
                              <MoreVertIcon />
                            </IconButton>
                          </Tooltip>

                          <Menu
                            className="custom_popover"
                            id="demo-positioned-menu "
                            aria-labelledby="demo-positioned-button"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                          >
                            <MenuItem
                              className="menuItem"
                              onClick={handleClose}
                            >
                              View
                            </MenuItem>
                            <MenuItem
                              className="menuItem"
                              onClick={handleDelete}
                            >
                              Delete
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>

{/***************  table footer and pagination  ******************* */}

              <TableFooter>
                <TableRow>
                  <TableCell colSpan={100} style={{ padding: "0" }}>
                    <div className="table_pagination">
                      <div className="items_per_page">
                        <Select
                          className="pagination_select"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={page}
                          onChange={handleChangePage}
                        >
                          <MenuItem value={10}>10</MenuItem>
                          <MenuItem value={20}>20</MenuItem>
                          <MenuItem value={30}>30</MenuItem>
                        </Select>
                        <p>Items per Page</p>
                      </div>

                      <Stack spacing={2}>
                        <Pagination count={10} />
                      </Stack>
                    </div>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </Div>
        </CardContent>
      </Card>
    </section>
  );
};


/***********  tabs function   ********/
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default UserManagement;
