import React from "react";
import JumboLayout from "@jumbo/components/JumboLayout";
import useJumboLayout from "@jumbo/hooks/useJumboLayout";
import layoutConfig from "./layoutConfig";



const AdminLayout = ({children}) => {
    const {setJumboLayoutOptions} = useJumboLayout();
    // const { isAuthenticated } = useContext (AuthenticationContext)

    React.useEffect(() => {
        setJumboLayoutOptions(layoutConfig);
        
       

    }, []);

    return (

        <JumboLayout>
         
            
                     
                
           
           
           


            
           
           <div style={{padding: "40px"}}>
           {children}
           </div>

            
        </JumboLayout>
    );
};

export default AdminLayout;
